import HelpIcon from '@material-ui/icons/HelpOutlineOutlined'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import { helpCenterActions } from 'ducks/helpCenter'
import { orderSelectors } from 'ducks/orderComponents'
import { Box, IconButton, styled } from 'opensolar-ui'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import SearchBarController from 'persistentContent/elements/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const NavigationIconButton = styled(IconButton)({
  color: '#4F4D55',
  fontSize: 12,
  fontWeight: 700,
  gap: 6,
  padding: 8,
  '&:hover': {
    color: '#0A090B',
  },
  '& svg': {
    height: 20,
    width: 20,
  },
})

const NavigationIconButtons = () => {
  const cartItems = useSelector(orderSelectors.getConfirmedLineItems)
  const totalQuantity = ProjectOrderPresenter.getLineItemsTotalQuantity(cartItems)

  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <Box alignItems="center" display="flex" gridGap={6}>
      <SearchBarController
        triggerIconButton={
          <NavigationIconButton size="small" variant="squared">
            <SearchOutlinedIcon />
          </NavigationIconButton>
        }
      />
      <NavigationIconButton onClick={() => history.push('/shop/cart')} size="small" variant="squared">
        <ShoppingCartOutlinedIcon />
        {totalQuantity > 0 && totalQuantity}
      </NavigationIconButton>
      <NavigationIconButton
        onClick={() => dispatch(helpCenterActions.setHelpCenterOpen(true))}
        size="small"
        variant="squared"
      >
        <HelpIcon />
      </NavigationIconButton>
    </Box>
  )
}

export default NavigationIconButtons
