export const STOCK_LEVEL_STATUS = {
  LOADING: 'loading',
  AVAILABLE: 'available',
  LOW_STOCK: 'low_stock',
  PARTIAL_AVAILABLE: 'partial_available',
  OUT_OF_STOCK: 'out_of_stock',
  NOT_AVAILABLE: 'not_available',
  AVAILABLE_FOR_PRE_ORDER: 'available_for_pre_order',
}

export const DISTRIBUTOR_LABEL = {
  segen: 'Segen',
  hdm: 'HDM Solar',
  city_plumbing: 'City Plumbing',
  outlet: 'Solar Outlet',
}

export const AFTER_CONNECT_REDIRECT_URL = {
  shop_cart_page: '/shop/cart',
}
