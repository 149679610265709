import { authSelectors } from 'ducks/auth'
import { CentrixCreditCheckButton } from 'projectSections/sections/info/transactions'
import { ButtonsRow } from 'projectSections/sections/manage3/common/ButtonsRow'
import { IconBtnWithPermissions } from 'projectSections/sections/manage3/common/IconBtnWithPermissions'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { reloadEntireApp } from 'util/misc'
import { getOrgFromState } from 'util/org'
import { NewFileMenu } from './files/common/menus/NewFileMenu'

import { FileSearchField } from './files/section/FileSearchField'
import { FilesAndTransactionsTab } from './FilesAndTransactionsSection'

export interface ToggleGroupTab {
  title: string
  icon: ReactElement
}

type ToggleGroupTabs = {
  [key: string]: ToggleGroupTab
}

interface PropTypes {
  tabSelected: FilesAndTransactionsTab
  setTabSelected: (t: FilesAndTransactionsTab) => void
  setOpenNewTransactionDialog: (isOpen: boolean) => void
  showFilesSearchField: boolean
  setShowFilesSearchField: (isOpen: boolean) => void
  filesSearchTerm: string
  setFilesSearchTerm: (term: string) => void
}

export const ToolBar: React.FC<PropTypes> = ({
  tabSelected,
  setOpenNewTransactionDialog,
  showFilesSearchField,
  setShowFilesSearchField,
  filesSearchTerm,
  setFilesSearchTerm,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const enableCentrixCreditCheck = useSelector(
    (state: RootState) => getOrgFromState(state)?.enable_centrix_credit_check
  )

  const orgId = useSelector(authSelectors.getOrgId) as number
  const projectId = useSelector((state: any) => state.projectId)

  return (
    <>
      {showFilesSearchField && (
        <div>
          <FileSearchField
            isOpen={showFilesSearchField}
            filesSearchTerm={filesSearchTerm}
            setFilesSearchTerm={setFilesSearchTerm}
            setShowFilesSearchField={setShowFilesSearchField}
          />
        </div>
      )}
      {!showFilesSearchField && tabSelected === 'documents' && (
        <ButtonsRow>
          <IconBtnWithPermissions
            permissionToCheck="info_documents"
            permissions={['allowView']}
            btnType={'search'}
            variant="squared"
            onClick={() => {
              setShowFilesSearchField(true)
            }}
          />
          <IconBtnWithPermissions
            permissionToCheck="info_documents"
            permissions={['allowCreate']}
            btnType={'add'}
            variant="squared"
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
            }}
            tooltip={'Add file'}
          />
          <NewFileMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          <IconBtnWithPermissions
            permissionToCheck="info_documents"
            permissions={['allowView']}
            btnType={'link'}
            variant="squared"
            to={`private_files/`}
            tooltip={'Go to project files'}
          />
        </ButtonsRow>
      )}
      {tabSelected === 'transactions' && (
        <div>
          {enableCentrixCreditCheck && (
            <CentrixCreditCheckButton
              org_id={orgId}
              project_id={projectId}
              refreshView={() => {
                // Used same implementation as in v2
                // -- We have not implemented a way to refresh the transactions list independently of the rest of the page
                // -- so we are lazy and we just reload the whole page.
                reloadEntireApp()
              }}
            />
          )}
          <IconBtnWithPermissions
            permissionToCheck="info_transactions"
            permissions={['allowCreate']}
            btnType={'add'}
            variant="squared"
            onClick={() => {
              setOpenNewTransactionDialog(true)
            }}
            tooltip={'Add transaction'}
          />
        </div>
      )}
    </>
  )
}
