import Grid from '@material-ui/core/Grid'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import { orgSelectors } from 'ducks/orgs'
import DateTimeInput from 'elements/input/DateTimeInput'
import { FormLabel } from 'opensolar-ui'
import React, { useContext } from 'react'
import { AutocompleteInput, BooleanInput, ReferenceInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { TRANSACTION_TYPE_CHOICES } from 'resources/transactions/Transactions'
import { taxNameForCountryIso2 } from 'util/misc'
import { required } from 'validations'
import { BooleanRow, FormSubSectionGrey, InputsCol } from '../files/common/styles/dialogAndFieldsStyles'

import { LOCKED_TRANSACTION_TYPES } from './consts'

export const TransactionFormFields: React.FunctionComponent<any> = (props) => {
  const translate = useTranslate()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const taxName = taxNameForCountryIso2(countryIso2)
  const capitalizedTaxName = taxName.charAt(0).toUpperCase() + taxName.slice(1)
  const currencySymbol = useContext(CurrencySymbolContext)

  const fincoSearchKey = props?.record?.transaction_type || ''

  const isFinco =
    props.dialogType === 'create'
      ? false
      : props?.record?.transaction_type && LOCKED_TRANSACTION_TYPES.includes(fincoSearchKey)

  return (
    <InputsCol>
      <InputsCol>
        {props.dialogType === 'edit' && (
          <div>
            <FormLabel>{translate('ID')}</FormLabel>
            <TextInput label={false} hiddenLabel variant="outlined" disabled={true} source="id" fullWidth />
          </div>
        )}
        <div>
          <FormLabel required>{translate('Amount')}</FormLabel>
          <TextInput
            startAdornment={currencySymbol}
            label={false}
            hiddenLabel
            variant="outlined"
            disabled={isFinco}
            fullWidth
            source="amount"
            validate={required}
          />
        </div>
        <div>
          <FormLabel>{translate('%{taxName} Included', { taxName: capitalizedTaxName })}</FormLabel>
          <TextInput
            startAdornment={currencySymbol}
            fullWidth
            label={false}
            hiddenLabel
            variant="outlined"
            disabled={!props.record}
            source="tax_included"
          />
        </div>
        <div>
          <FormLabel>{translate('Surcharge Amount')}</FormLabel>
          <TextInput
            startAdornment={currencySymbol}
            fullWidth
            label={false}
            hiddenLabel
            variant="outlined"
            disabled={!props.record}
            source="surcharge_amount"
          />
        </div>
        {props.dialogType === 'edit' && (
          <div>
            <FormLabel>{translate('Project')}</FormLabel>
            <ReferenceInput
              source="project"
              reference="projects"
              optionValueField="url"
              allowEmpty
              renderChildrenBeforeRecord={true}
              fullWidth
              label={''}
              hiddenLabel
            >
              {/* @ts-ignore */}
              <AutocompleteInput
                options={{
                  placeholder: 'Enter a project',
                  disabled: isFinco,
                }}
                fullWidth
                optionText="address"
                optionValue="url"
                variant="outlined"
              />
            </ReferenceInput>
          </div>
        )}
        <BooleanRow>
          <p>{translate('Payment is complete')} </p>
          <BooleanInput options={{ color: 'primary' }} label="" source="is_complete" disabled={isFinco} />
        </BooleanRow>
      </InputsCol>

      <FormSubSectionGrey>
        <h2>
          {translate('Details for Completed Payments')} ({translate('leave empty for payment requests')})
        </h2>
        <InputsCol>
          <Grid item xs={12}>
            <FormLabel>{translate('Transaction Type')}</FormLabel>
            <SelectInput
              source="transaction_type"
              choices={TRANSACTION_TYPE_CHOICES}
              allowEmpty
              fullWidth={true}
              style={{ width: '100%' }}
              disabled={isFinco}
              variant="outlined"
              label={false}
            />
          </Grid>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <DateTimeInput
              source="transaction_datetime"
              options={{
                inputVariant: 'outlined',
                format: 'MM/DD/YYYY h:m A',
              }}
              label={'Transaction Date and Time'}
              fullWidth
            />
          </Grid>
          <BooleanRow>
            <p>{translate('Funds are confirmed')} </p>
            <BooleanInput options={{ color: 'primary' }} source="funds_confirmed" label={false} disabled={isFinco} />
          </BooleanRow>
        </InputsCol>
      </FormSubSectionGrey>
    </InputsCol>
  )
}

export default TransactionFormFields
