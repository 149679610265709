import { Add, ArrowBackIosOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import StyledNavLink from 'layout/headers/elements/StyledNavLink'
import useNavigationLinks, { HOME_NAVIGATION_LINK } from 'layout/hooks/useNavigationLinks'
import { Box, Button, ComponentVersionsInherit, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DialogHelper } from 'util/misc'
import NavigationIconButtons, { NavigationIconButton } from './NavigationIconButtons'
import NavigationSettingsMenu from './NavigationSettingsMenu'

const NavContainer = styled(Box)(() => ({
  padding: '0px 24px',
  position: 'relative',
  '&::after': {
    background: 'rgb(236, 236, 237)',
    bottom: '0px',
    content: "''",
    height: '1px',
    left: '0',
    position: 'absolute',
    width: '100%',
  },
}))

interface TopNavigationHeader3Props {
  navigationBackUrl: string
  setAvailableOrgs: (v) => void
  setSelectOrgVisible: (v) => void
}

const TopNavigationHeader3: React.FC<TopNavigationHeader3Props> = ({
  navigationBackUrl,
  setAvailableOrgs,
  setSelectOrgVisible,
}) => {
  const briefOrgs = useSelector(authSelectors.getBriefOrgs)

  const navigationLinks = useNavigationLinks()
  const history = useHistory()
  const translate = useTranslate()

  return (
    <ComponentVersionsInherit versions={{ button: 3, icon_button: 2 }}>
      <NavContainer alignItems="center" display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex" gridColumnGap={12}>
          {navigationBackUrl && (
            <NavigationIconButton
              onClick={() => {
                window.location.href = navigationBackUrl
              }}
              size="small"
              variant="squared"
            >
              <ArrowBackIosOutlined />
            </NavigationIconButton>
          )}
          <StyledNavLink to={`/${HOME_NAVIGATION_LINK.key}`} label={HOME_NAVIGATION_LINK.label}>
            <img src={`${window.PUBLIC_URL}/images/logo.svg`} />
          </StyledNavLink>
          {navigationLinks.map(({ badgeSeverity, label, key, uiKey }) => (
            <StyledNavLink badgeSeverity={badgeSeverity} uiKey={uiKey} to={`/${key}`} label={label} />
          ))}
        </Box>
        <Box display="flex" gridGap={12} padding="8px 4px">
          <NavigationIconButtons />
          <Button
            color="secondary"
            onClick={() => history.push('/home')}
            startIcon={<Add />}
            variant="contained"
            style={{ lineHeight: 1 }}
          >
            {translate('Project')}
          </Button>
          <NavigationSettingsMenu
            showOrgSelector={() => {
              DialogHelper.afterOpen()
              setSelectOrgVisible(true)
              setAvailableOrgs(briefOrgs)
            }}
          />
        </Box>
      </NavContainer>
    </ComponentVersionsInherit>
  )
}

// @ts-ignore
export default TopNavigationHeader3
