import { Grid, InputAdornment, Tooltip, makeStyles } from '@material-ui/core'
import { DeleteOutline, Info } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { Button } from 'opensolar-ui'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BooleanInput, NumberInput, SelectArrayInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import {
  getDefaultPaymentMethodsForCountry,
  getIsSurchargingAllowed,
  showDebitCardSurchargeWarning,
  useGetPaymentMethodChoices,
} from '../utils'
import SurchargingTooltip from './SurchargingTooltip'

type PropTypes = {
  paymentIndex: number
  businessState: string
}

const useStyles = makeStyles<OpenSolarThemeType, { paymentNumber: number; totalMilestones: number }>((theme) => ({
  milestoneContainer: {
    background: theme.white,
    padding: '16px 24px',
    border: '1px solid #e7e7e7',
    borderBottomLeftRadius: ({ totalMilestones, paymentNumber }) => (paymentNumber < totalMilestones ? '0' : '5px'),
    borderBottomRightRadius: ({ totalMilestones, paymentNumber }) => (paymentNumber < totalMilestones ? '0' : '5px'),
  },

  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  fieldRow: {
    margin: '5px 0',
  },

  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },

  infoIcon: {
    fontSize: 18,
    color: theme.greyMid1,
  },
}))

const useCustomStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-root': {
      flexFlow: 'row',
      marginRight: 10,
    },
  },
}))
//

const MilestoneFields: React.FC<PropTypes> = (props) => {
  // if the milestone is the last in the list of milestones and the list of milestones is longer than 1 then the user cannot choose
  // the percentage, min or max and the milestone will always have to have set_value_to_remaining_amount_due = true
  const [mustUseRemainingValue, setMustUseRemainingValue] = useState<boolean>(false)

  const formState = useFormState()
  const form = useForm()
  const translate = useTranslate()
  const allMilestones = formState.values.payment_milestone_configurations
  const paymentMethodChoices = useGetPaymentMethodChoices()
  const orgCountry = useSelector(orgSelectors.getOrg)?.country?.iso2

  // human-friendly value that isn't zero-indexed
  const paymentNumber = useMemo(() => {
    return props.paymentIndex + 1
  }, [props.paymentIndex])

  useEffect(() => {
    setMustUseRemainingValue(props.paymentIndex > 0 && props.paymentIndex + 1 === allMilestones.length)
  }, [props.paymentIndex, allMilestones?.length])

  // add country-specific initial payment method values for each milestone if this is a new config that hasn't yet been saved
  useEffect(() => {
    if (!formState.values?.id && orgCountry) {
      formState.values?.payment_milestone_configurations?.forEach((config, i) => {
        let defaultOptions: string[] = []
        if (i === 0) {
          defaultOptions = getDefaultPaymentMethodsForCountry(orgCountry, true, props.businessState)
        } else {
          defaultOptions = getDefaultPaymentMethodsForCountry(orgCountry, false, props.businessState)
        }
        form.change(`payment_milestone_configurations[${i}].accepted_payment_methods`, defaultOptions)
      })
    }
  }, [props.businessState])

  const onDelete = useCallback(() => {
    let currentMilestones = [...allMilestones]
    currentMilestones.splice(paymentNumber - 1, 1)
    form.change('payment_milestone_configurations', currentMilestones)
  }, [allMilestones])

  const surchargingIsAllowed = useMemo(() => {
    return getIsSurchargingAllowed(orgCountry)
  }, [orgCountry])

  const debitCardSurchargingWarning = useMemo(() => {
    return showDebitCardSurchargeWarning(
      formState.values.payment_milestone_configurations[props.paymentIndex].surcharging_enabled,
      formState.values.payment_milestone_configurations[props.paymentIndex].accepted_payment_methods
    )
  }, [formState.values])

  const classes = useStyles({ paymentNumber, totalMilestones: allMilestones.length })
  const switchClasses = useCustomStyles()

  const isRemovedFromPercentageCalcs = useMemo(() => {
    return Boolean(
      formState.values?.payment_milestone_configurations?.[props.paymentIndex]?.remove_from_percentage_calcs
    )
  }, [formState.values])

  return (
    <div className={classes.milestoneContainer}>
      <div className={classes.headerRow}>
        <h2>Milestone {paymentNumber}</h2>
        {paymentNumber > 1 && (
          <Button variant="text" onClick={onDelete}>
            <DeleteOutline />
          </Button>
        )}
      </div>
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            label={`Milestone ${paymentNumber} Title`}
            variant="outlined"
            fullWidth
            source={`payment_milestone_configurations[${props.paymentIndex}].title`}
            className={classes.fieldRow}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label={`Milestone ${paymentNumber} description`}
            variant="outlined"
            fullWidth
            source={`payment_milestone_configurations[${props.paymentIndex}].description`}
            className={classes.fieldRow}
          />
        </Grid>
        {paymentNumber === 1 && (
          <>
            <Grid item xs={12}>
              <div className={classes.flexRow}>
                <BooleanInput
                  label="Collect payment when proposal is accepted"
                  source={`payment_milestone_configurations[${props.paymentIndex}].is_deposit`}
                  classes={switchClasses}
                  labelPlacement="end"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.flexRow}>
                <BooleanInput
                  label="Exclude amount from other milestone calculations"
                  source={`payment_milestone_configurations[${props.paymentIndex}].remove_from_percentage_calcs`}
                  classes={switchClasses}
                  labelPlacement="end"
                />
                <span>
                  <Tooltip
                    title="Set a fixed value for the first milestone and remaining amount due is split between milestones by percentage."
                    enterDelay={300}
                    className={classes.infoIcon}
                  >
                    <Info />
                  </Tooltip>
                </span>
              </div>
            </Grid>
          </>
        )}
        {surchargingIsAllowed && (
          <Grid item xs={12}>
            <div className={classes.flexRow}>
              <BooleanInput
                label="Enable Credit Card Surcharging"
                classes={switchClasses}
                source={`payment_milestone_configurations[${props.paymentIndex}].surcharging_enabled`}
              />
              <span>
                <SurchargingTooltip />
              </span>
            </div>
          </Grid>
        )}

        {!mustUseRemainingValue && (
          <Grid container spacing={2}>
            {isRemovedFromPercentageCalcs ? (
              <Grid item xs={12}>
                <NumberInput
                  label="Fixed Amount"
                  source={`payment_milestone_configurations[${props.paymentIndex}].fixed_amount`}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  fullWidth
                  className={classes.fieldRow}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={4}>
                  <NumberInput
                    variant="outlined"
                    fullWidth
                    label="Amount Due"
                    source={`payment_milestone_configurations[${props.paymentIndex}].percentage`}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    className={classes.fieldRow}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberInput
                    variant="outlined"
                    fullWidth
                    label="Min Amount (Optional)"
                    source={`payment_milestone_configurations[${props.paymentIndex}].min_fixed`}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    className={classes.fieldRow}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberInput
                    variant="outlined"
                    fullWidth
                    label="Max Amount (Optional)"
                    source={`payment_milestone_configurations[${props.paymentIndex}].max_fixed`}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    className={classes.fieldRow}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
        <NumberInput
          label="Payment Number"
          source={`payment_milestone_configurations[${props.paymentIndex}].payment_number`}
          hidden={true}
          value={paymentNumber}
          style={{ display: 'none' }}
          className={classes.fieldRow}
        />
        <SelectArrayInput
          label="Accepted Payment Methods"
          source={`payment_milestone_configurations[${props.paymentIndex}].accepted_payment_methods`}
          choices={paymentMethodChoices}
          fullWidth={true}
          className={classes.fieldRow}
        />

        {debitCardSurchargingWarning && (
          <Alert severity="info">
            <span>
              {translate(
                'Debit cards are not eligible for surcharging. If the customer chooses to pay with a debit card then all fees will be taken out of the payment amount.'
              )}
            </span>
          </Alert>
        )}
      </Grid>
    </div>
  )
}
export default MilestoneFields
