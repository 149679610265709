import { useMemo } from 'react'
import { useNotify } from 'react-admin'
import restClient from 'restClient'
import CityPlumbingService from './CityPlumbingService'

const restClientInstance = restClient(window.API_ROOT + '/api')
const useCityPlumbingService = (): CityPlumbingService => {
  const notify = useNotify()
  const cityPlumbingService = useMemo(() => new CityPlumbingService(restClientInstance, notify), [
    restClientInstance,
    notify,
  ])

  return cityPlumbingService
}

export default useCityPlumbingService
