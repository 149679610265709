import { makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { projectViewSettingsActions } from 'ducks/projectViewSettings'
import {
  ArrowExpandOutlineIcon,
  FileOutlineIcon,
  HourGlassOutlineIcon,
  IconButton,
  NotificationBoxOutline,
  styled,
  ToggleButton,
  ToggleButtonGroup,
} from 'opensolar-ui'
import { COLOR_PALETTE, getProjectSectionStyles, IconButtonTooltip } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EventCategoryEnum, EventFormVariant } from 'types/events'
import { Theme } from 'types/themes'
import CustomDropdownInput from '../../../common/CustomDropdownInput'

const ActivityActions = styled('div', { name: 'ActivityActions' })({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
})

const useStyles = makeStyles((theme: Theme) => ({
  addBtn: {
    marginLeft: 'auto !important',
  },
  marginLeft: {
    marginLeft: 'auto',
  },
  icon: {
    marginRight: 5,
    height: 10,
  },
  iconBtn: {
    marginLeft: '5px !important',
  },
  wrapper: {
    padding: 10,
    flexWrap: 'wrap',
    rowGap: 10,
  },
}))

interface PropTypes {
  filterEvents: (filter: string | EventCategoryEnum) => void
  toggleModal: (event) => void
  minDisplay?: boolean
  filterApplied: string | EventCategoryEnum
}

const HistoryToolbar: React.FC<PropTypes> = ({ filterEvents, toggleModal, minDisplay, filterApplied }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const orgId = useSelector(authSelectors.getOrgId)
  const currRole = useSelector(authSelectors.getCurrentRole)
  const currRoleUrl = useMemo(
    () => (currRole ? `${window.API_ROOT}/api/orgs/${orgId}/roles/${currRole.id}/` : undefined),
    [currRole]
  )
  const handleAddNote = () => {
    toggleModal({ event_type_id: 31, form_config: EventFormVariant.note })
  }
  const handleAddTask = () => {
    toggleModal({
      team_members: [currRoleUrl],
      notify_team_members: currRole?.id ? [currRole?.id] : [],
      form_config: EventFormVariant.task,
    })
  }
  const addActions = useMemo(
    () => [
      {
        label: translate('Add Note'),
        onClick: handleAddNote,
      },
      {
        label: translate('Add Task'),
        onClick: handleAddTask,
      },
    ],
    [toggleModal]
  )
  const handleFilterChange = (event, value) => {
    if (value != null) filterEvents(value)
  }
  if (minDisplay)
    return (
      <div className={`${sectionStyles.sectionCardTop} ${sectionStyles.row} ${classes.wrapper}`}>
        <ToggleButtonGroup exclusive value={filterApplied} onChange={handleFilterChange}>
          <ToggleButton value={'activities'}>
            <div className={`${classes.icon} ${sectionStyles.row}`}>
              <HourGlassOutlineIcon variant={1} size={15} />
            </div>
            {translate('Activities')}
          </ToggleButton>
          <ToggleButton value={EventCategoryEnum.note}>
            <div className={`${classes.icon} ${sectionStyles.row}`}>
              <FileOutlineIcon variant={2} size={15} />
            </div>
            {translate('Notes')}
          </ToggleButton>
          <ToggleButton value={EventCategoryEnum.task}>
            <div className={`${classes.icon} ${sectionStyles.row}`}>
              <NotificationBoxOutline size={15} />
            </div>
            {translate('Tasks')}
          </ToggleButton>
        </ToggleButtonGroup>
        <ActivityActions>
          <CustomDropdownInput actions={addActions} emptyText={translate('Add')} variant="outlined" />
          <IconButtonTooltip title={translate('View all')}>
            <IconButton
              className={classes.iconBtn}
              variant="squared"
              onClick={() => {
                dispatch(projectViewSettingsActions.setHistoryOpen(true))
              }}
            >
              <ArrowExpandOutlineIcon variant={1} color={COLOR_PALETTE.darkGrey} />
            </IconButton>
          </IconButtonTooltip>
        </ActivityActions>
      </div>
    )

  return (
    <div className={sectionStyles.row}>
      <ToggleButtonGroup exclusive value={filterApplied} onChange={handleFilterChange}>
        <ToggleButton value={'all'}>{translate('All')}</ToggleButton>
        <ToggleButton value={EventCategoryEnum.system_activity}>{translate('System Activities')}</ToggleButton>
        <ToggleButton value={EventCategoryEnum.customer_activity}>{translate('Customer Activities')}</ToggleButton>
        <ToggleButton value={EventCategoryEnum.note}>{translate('Notes')}</ToggleButton>
        <ToggleButton value={EventCategoryEnum.task}>{translate('Tasks')}</ToggleButton>
      </ToggleButtonGroup>
      <CustomDropdownInput
        actions={addActions}
        className={classes.addBtn}
        emptyText={translate('Add')}
        variant="outlined"
      />
    </div>
  )
}

export default HistoryToolbar
