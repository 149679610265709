import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Tooltip, makeStyles } from '@material-ui/core'
import { ExpandLess, ExpandMore, Info } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import EditorComponent from 'elements/wysiwyg/EditorComponentWrapper'
import { useTranslate } from 'ra-core'
import { TextInput } from 'ra-ui-materialui'
import React, { useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getIsValidEmail } from 'util/misc'
import RecipientToggle from '../internalNotifications/RecipientToggle'

type PropTypes = {
  header: string
  description?: string
  badge?: boolean
  label: string
}

const useStyles = makeStyles<OpenSolarThemeType, { type?; bullet?; order?; isExpanded? }>((theme) => ({
  container: {
    background: theme.white,
    padding: '10px 0 30px 0',
    border: '1px solid #e7e7e7',
    borderRadius: '5px',
    margin: '1rem 0',
  },

  contentWrapper: {
    padding: '5px 24px',
  },

  row: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },

  badge: {
    padding: '4px 8px',
    border: '1px solid',
    borderColor: theme.greyLight2,
    borderRadius: 5,
    fontSize: 12,
  },

  recipientContainer: {
    position: 'relative',
    margin: '1rem 0',
  },

  labelWrapper: {
    top: '-8px',
    left: '8px',
    position: 'absolute',
    padding: '0 5px',
    background: theme.white,
  },

  label: {
    fontSize: 10,
  },

  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 5,
    padding: '15px 8px',
    border: '2px solid #E7E7E7',
    borderRadius: 4,
    marginTop: 2,
  },

  chip: {
    background: '#F1F1F1',
    border: '1px solid #F1F1F1',
    borderRadius: 5,
    padding: 5,
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
  },

  field: {
    margin: '8px 0',
  },

  disabledField: {
    margin: '8px 0',
  },

  icon: {
    fontSize: 20,
  },

  accordion: {
    border: 'none !important',
    borderRadius: 0,
    background: ({ isExpanded }) => (isExpanded ? '#F5F5F5' : theme.white),
    margin: 0,
  },

  accordionHeaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    color: ({ isExpanded }) => (isExpanded ? '#0094FF' : '#7F7D83'),
  },

  subHeader2: {
    fontWeight: 500,
    fontSize: 15,
  },

  flexRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '25px 0 5px 0',
  },

  infoIcon: {
    fontSize: 18,
    color: theme.greyMid1,
  },

  contactsContainer: {
    width: '100%',
  },

  messageContainer: {
    padding: 10,
    border: '2px solid #E7E7E7',
    borderRadius: 4,
  },

  otherRecipientsField: {
    background: theme.white,
  },
}))

const EMAIL_RECIPIENTS_MAP = {
  assigned_team_member: 'Assigned Team Member',
  assigned_salesperson: 'Salesperson',
  assigned_designer: 'Designer',
  assigned_site_inspector: 'Site Inspector',
  assigned_installer: 'Installer',
  connected_team_contact: 'Connected Team Contact',
}

const EmailTemplate: React.FC<PropTypes> = ({ header, description, badge, label }) => {
  const form = useForm()
  const formValues = useFormState().values

  const project_recipients = formValues?.email_templates?.[label]?.project_recipients
  const other_recipients = formValues?.email_templates?.[label]?.other_recipients

  const org_name = useSelector(orgSelectors.getOrg)?.name
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [emailBcc, setEmailBcc] = useState<string[]>(project_recipients)
  const [addtlBccEmails, setAddtlBccEmails] = useState<string[]>(other_recipients)
  const [emailInput, setEmailInput] = useState<string>((addtlBccEmails && addtlBccEmails?.join(', ')) || '')

  const classes = useStyles({ type: '', bullet: '', isExpanded: isExpanded })
  const translate = useTranslate()

  useMemo(() => {
    if (formValues) form.change(`email_templates.${label}.project_recipients`, emailBcc)
  }, [emailBcc])

  const addOtherRecipients = (emailString) => {
    let validEmails = []
    setEmailInput(emailString)

    if (emailString) {
      const emailArr = emailString.split(',')?.map((email) => email.trim())
      validEmails = emailArr?.filter((email) => getIsValidEmail(email)) || []
      setAddtlBccEmails(validEmails)
    } else {
      setAddtlBccEmails([])
    }

    form.change(`email_templates.${label}.other_recipients`, validEmails)
  }

  const subject = formValues?.email_templates?.[label]?.subject_template?.replace('{{ org_name }}', org_name)
  form.change(`email_templates.${label}.subject_template`, subject)

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.contentWrapper}>
          <div className={classes.row}>
            <h1>{header && translate(header)}</h1>
            {badge && <span className={classes.badge}>{translate('Automated')}</span>}
          </div>
          <p>{description && translate(description)}</p>

          <RecipientContainer label="To" content={[translate('Primary Customer')]} />
          <RecipientContainer label="Bcc" content={emailBcc?.concat(addtlBccEmails)} />
        </div>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            <div className={classes.accordionHeaderContainer}>
              {translate('Recipient settings')}
              {isExpanded ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} />}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            <div className={classes.contactsContainer}>
              <div className={classes.flexRow}>
                <p className={classes.subHeader2}>{translate("Select Project Contacts to be BCC'ed")}</p>
                <Tooltip
                  title={translate(
                    "Select which project contacts should receive emails sent based on project-level activity. Add any email addresses in the 'Add other email addresses' box for contacts who should receive all emails of this type"
                  )}
                  enterDelay={300}
                  className={classes.infoIcon}
                >
                  <Info />
                </Tooltip>
              </div>

              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <RecipientToggle
                    isToggledOn={emailBcc?.includes('assigned_team_member')}
                    label="assigned_team_member"
                    setEmailBcc={setEmailBcc}
                  />
                  <RecipientToggle
                    isToggledOn={emailBcc?.includes('assigned_salesperson')}
                    label="assigned_salesperson"
                    setEmailBcc={setEmailBcc}
                  />
                  <RecipientToggle
                    isToggledOn={emailBcc?.includes('assigned_installer')}
                    label="assigned_installer"
                    setEmailBcc={setEmailBcc}
                  />
                </Grid>

                <Grid item xs={4}>
                  <RecipientToggle
                    isToggledOn={emailBcc?.includes('assigned_designer')}
                    label="assigned_designer"
                    setEmailBcc={setEmailBcc}
                  />
                  <RecipientToggle
                    isToggledOn={emailBcc?.includes('assigned_site_inspector')}
                    label="assigned_site_inspector"
                    setEmailBcc={setEmailBcc}
                  />
                </Grid>

                <Grid item xs={4}>
                  <p>{translate('Add other email addresses')}</p>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={`${label}_emails`}
                    fullWidth
                    multiline={true}
                    rows={3}
                    placeholder={translate('Add email addresses separated by comma')}
                    value={emailInput}
                    onChange={(e) => addOtherRecipients(e.target.value)}
                    className={classes.otherRecipientsField}
                  />
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>

        <div className={classes.contentWrapper}>
          {label !== 'payment_authorized' ? (
            <>
              <CustomField
                label="Subject"
                component={TextInput}
                name={`email_templates.${label}.subject_template`}
                source={`email_templates.${label}.subject_template`}
                size="small"
                variant="outlined"
                className={classes.field}
                fullWidth
              />

              <CustomField
                component={EditorComponent}
                label=""
                name={`email_templates.${label}.body_template`}
                source={`email_templates.${label}.body_template`}
                mentionContext="org"
                allowUploadImage={true}
              />
            </>
          ) : (
            <>
              <TextField
                value={`Thank you for your payment to ${org_name}`}
                size="small"
                variant="outlined"
                label="Subject"
                className={classes.disabledField}
                fullWidth
                InputProps={{ readOnly: true }}
              />
              <div className={classes.messageContainer}>
                <p>Hi [Customer Name],</p>
                <br />
                <p>
                  Thank you for your payment. This message serves as a confirmation that you have authorized the
                  following payment:
                </p>
                <br />
                <p>Provider: [Company Name]</p>
                <p>Payment Amount: [Amount]</p>
                <p>Payment Method: [Credit Card/Bank Transfer/etc.]</p>
                <p>Payment Date: [Payment Date]</p>
                <br />
                <p>Please find attached your paid invoice for your records.</p>
                <br />
                <p>Thank you,</p>
                <p>[Company Name]</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const RecipientContainer = ({ label, content }) => {
  const classes = useStyles({})
  const translate = useTranslate()

  return (
    <div className={classes.recipientContainer}>
      <div className={classes.labelWrapper}>
        <span className={classes.label}>{label}</span>
      </div>
      <div className={classes.chipContainer}>
        {label === 'To' ? (
          <Tooltip
            title={translate(
              'To: recipient fields are not configurable and are set as the contacts for individual projects'
            )}
            enterDelay={300}
          >
            <span className={classes.chip}>{content[0]}</span>
          </Tooltip>
        ) : (
          content?.map((contact) => {
            return (
              contact && (
                <span key={contact} className={classes.chip}>
                  {contact in EMAIL_RECIPIENTS_MAP ? translate(EMAIL_RECIPIENTS_MAP[contact]) : contact}
                </span>
              )
            )
          })
        )}
      </div>
    </div>
  )
}

export default EmailTemplate
