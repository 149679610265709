export const TRANSFER_STATUS_OPTIONS = [
  { id: 'Overdue', name: 'Overdue' },
  { id: 'Received', name: 'Received' },
  { id: 'Submitted', name: 'Submitted' },
  { id: 'Partially received', name: 'Partially received' },
]

export const ORDER_STATUS_OPTIONS = [
  { id: 'Quote Submitted', name: 'Quote Submitted' },
  { id: 'Ordered', name: 'Ordered' },
  { id: 'Processing', name: 'Processing' },
  { id: 'Delivered', name: 'Delivered' },
]

export const TRANSFER_TYPE = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
}

export const TRANSFER_ISSUED_REASON = {
  STOCKTAKE: 'Stocktake',
  INCOMING_PO: 'Incoming PO',
  CONSUMED: 'Consumed',
  DAMAGED: 'Damaged',
  RESTOCKED: 'Restocked',
  RETURNED: 'Returned',
  SOLD: 'Sold',
  STOLEN: 'Stolen',
}

export const TRANSFER_ISSUED_REASON_OPTIONS = [
  { id: 'Stocktake', name: TRANSFER_ISSUED_REASON.STOCKTAKE },
  { id: 'Incoming PO', name: TRANSFER_ISSUED_REASON.INCOMING_PO },
  { id: 'Consumed', name: TRANSFER_ISSUED_REASON.CONSUMED },
  { id: 'Damaged', name: TRANSFER_ISSUED_REASON.CONSUMED },
  { id: 'Restocked', name: TRANSFER_ISSUED_REASON.RESTOCKED },
  { id: 'Returned', name: TRANSFER_ISSUED_REASON.RETURNED },
  { id: 'Sold', name: TRANSFER_ISSUED_REASON.SOLD },
  { id: 'Stolen', name: TRANSFER_ISSUED_REASON.STOLEN },
]
