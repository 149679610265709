var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar as MuiAppBar, Toolbar, Tooltip, makeStyles, useMediaQuery } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { IconButton } from 'opensolar-ui';
import PropTypes from 'prop-types';
import { toggleSidebar, useTranslate } from 'ra-core';
import { Children, cloneElement, createElement } from 'react';
import { useDispatch } from 'react-redux';
import DefaultUserMenu from './UserMenu';
var useStyles = makeStyles(function (theme) { return ({
    // toolbar: {
    //   paddingRight: 24,
    // },
    menuButton: {
        margin: 0,
        marginLeft: 5,
        marginRight: 5,
        padding: 10,
    },
    menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}); }, { name: 'RaAppBar' });
/**
 * The AppBar component renders a custom MuiAppBar.
 *
 * @param {ReactNode} children React node/s to be render as children of the AppBar
 * @param {Object} classes CSS class names
 * @param {string} className CSS class applied to the MuiAppBar component
 * @param {string} color The color of the AppBar
 * @param {Component} logout The logout button component that will be pass to the UserMenu component
 * @param {boolean} open State of the <Admin/> Sidebar
 * @param {Element} userMenu A custom user menu component for the AppBar. <UserMenu/> component by default
 *
 * @example
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props}>
 *           <Typography
 *               variant="h6"
 *               color="inherit"
 *               className={classes.title}
 *               id="react-admin-title"
 *           />
 *        </AppBar>
 *    );
 *};
 */
var AppBar = function (props) {
    var children = props.children, classesOverride = props.classes, className = props.className, _a = props.color, color = _a === void 0 ? 'inherit' : _a, hasSideBar = props.hasSideBar, isTopNav3Enabled = props.isTopNav3Enabled, logo = props.logo, logout = props.logout, open = props.open, title = props.title, header = props.header, userMenu = props.userMenu, rest = __rest(props, ["children", "classes", "className", "color", "hasSideBar", "isTopNav3Enabled", "logo", "logout", "open", "title", "header", "userMenu"]);
    var classes = useStyles(props);
    var dispatch = useDispatch();
    var isXSmall = useMediaQuery(function (theme) { return theme.breakpoints.down('xs'); });
    var translate = useTranslate();
    return (_jsx(MuiAppBar, __assign({ style: isTopNav3Enabled
            ? { boxShadow: 'none' }
            : { boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px' }, position: 'relative', className: className, color: color }, rest, { children: _jsxs(Toolbar, __assign({ style: {
                minHeight: 44,
                backgroundColor: 'rgb(255,255,255)',
            }, disableGutters: true, variant: isXSmall ? 'regular' : 'dense', className: classes.toolbar }, { children: [isXSmall && hasSideBar && (_jsx(Tooltip, __assign({ title: translate(open ? 'ra.action.close_menu' : 'ra.action.open_menu', {
                        _: 'Open/Close menu',
                    }), enterDelay: 500 }, { children: _jsx(IconButton, __assign({ color: "inherit", onClick: function () { return dispatch(toggleSidebar()); }, className: classNames(classes.menuButton) }, { children: _jsx(MenuIcon, { classes: {
                                root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
                            } }) })) }))), Children.count(children) === 0
                    ? // <Typography variant='h6' color='inherit' className={classes.title} id='react-admin-title' />
                        null
                    : children, header ? createElement(header) : cloneElement(userMenu, { logout: logout })] })) })));
};
AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent']),
    isTopNav3Enabled: PropTypes.bool,
    logout: PropTypes.element,
    open: PropTypes.bool,
    userMenu: PropTypes.element,
};
AppBar.defaultProps = {
    userMenu: _jsx(DefaultUserMenu, {}),
    hasSideBar: true,
    isTopNav3Enabled: false,
};
export default AppBar;
