import designFields from 'projectSections/sections/design/fields'
import energyFields from 'projectSections/sections/energy/fields'
import infoFields from 'projectSections/sections/info/fields'
import manageFields from 'projectSections/sections/manage/fields'
import onlineProposalFields from 'projectSections/sections/proposal/fields'

export const FIELDS_BY_PAGES = {
  info: infoFields,
  energy: energyFields,
  manage: manageFields,
  onlineProposal: onlineProposalFields,
  design: designFields,
}

export const SHARE_FIELD_LABELS = {
  configuration_override: 'Setbacks & Design Settings',
  proposal_template: 'Proposal Template',
  contract_by_project: 'Contract Template',
  contract_by_system: 'Contract Template',
  testimonials: 'Case Studies',
  pricing_schemes: 'Pricing Scheme',
  costing_schemes: 'Costing Scheme',
  adders: 'Adders',
  payment_options: 'Payment Options',
  battery_control_scheme: 'Battery Control Scheme',
  components: 'Components',
  batteries: 'Batteries',
  inverters: 'Inverters',
  module: 'Modules',
  others: 'Others',
  actions: 'Project Actions',
  incentives: 'Incentives',
  tariffs: 'Tariffs',
  workflow: 'Project Workflow',
}

export const FIELDS_REQUIRE_RECALC = [
  'simulate_first_year_only',
  'is_residential',
  'utility_tariff_current',
  'utility_tariff_proposed',
  'utility_tariff_current_custom',
  'utility_tariff_proposed_custom',
  'usage',
  'country_iso2',
  'state',
  'zip',
  'number_of_storeys',
  'roof_type',
  'number_of_phases',
  'power_factor',
  'configuration_override',
  'costing_override',
  'language_override',
  'federal_income_tax_rate_percentage',
  'state_income_tax_rate_percentage',
  'assigned_role',
  'custom_data',
]

export const ALL_FIELDS = Object.values(FIELDS_BY_PAGES).flat()
