import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { HotTub } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import { AFTER_CONNECT_REDIRECT_URL } from './constants'
import useAutoSyncSegenPrice from './sync/useAutoSyncSegenPrice'
import useAutoSyncVtacPrice from './sync/useAutoSyncVtacPrice'
import useSaveAccessTokensAfterLogin from './sync/useSaveAccessTokensAfterLogin'
import { HardwareSupplierEnum } from './type'

const VtacAfterConnectPage = React.memo(() => {
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId)
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)

  const distributor = useMemo(() => {
    if (selectedSupplier === HardwareSupplierEnum.VtacUk) return 'vtac_uk'
    else if (selectedSupplier === HardwareSupplierEnum.VtacPoland) return 'vtac_pl'
    return null
  }, [selectedSupplier])

  const { isSyncing, syncVtacPrices } = useAutoSyncVtacPrice()

  const doSync = useCallback(
    async (orgId) => {
      if (orgId && distributor) {
        debugger
        await syncVtacPrices({ distributor })
        history.push('/shop')
      }
    },
    [orgId, distributor]
  )

  useEffect(() => {
    if (orgId) {
      doSync(orgId)
    }
  }, [orgId])

  if (isSyncing) {
    return <FullScreenLoading />
  }
  return <></>
})

const HdmAfterConnectPage = React.memo(() => {
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId)
  const location = useLocation()
  const params = parseQueryStringToDictionary(location.search)

  const { isSyncing, saveAccessTokens } = useSaveAccessTokensAfterLogin()

  const doSync = useCallback(
    async (orgId, refreshToken) => {
      if (orgId && refreshToken) {
        await saveAccessTokens({ orgId, refreshToken, supplierFilterKey: 'hdm' })
        history.push('/shop')
      }
    },
    [orgId]
  )

  useEffect(() => {
    if (orgId && params.code) {
      doSync(orgId, params.code)
    }
  }, [orgId, params.code])

  if (isSyncing) {
    return <FullScreenLoading />
  }
  return <></>
})

const CityPlumbingAfterConnectPage = React.memo(() => {
  const auth = useAuth()
  const history = useHistory()
  const location = useLocation()
  const orgId = useSelector(authSelectors.getOrgId)
  const { isSyncing, saveAccessTokens } = useSaveAccessTokensAfterLogin()
  const params = parseQueryStringToDictionary(location.search)
  const redirect_url = params?.redirect_url ? AFTER_CONNECT_REDIRECT_URL[params?.redirect_url] : '/shop'

  const initialising = useMemo(() => {
    return auth?.isLoading || isSyncing
  }, [isSyncing, auth])

  const doSync = useCallback(
    async (orgId, refreshToken) => {
      if (orgId && refreshToken) {
        await saveAccessTokens({ orgId, refreshToken, supplierFilterKey: 'city_plumbing' })
        history.push({ pathname: redirect_url, state: { from: '/shop/after_connect/city_plumbing' } })
      }
    },
    [orgId]
  )

  useEffect(() => {
    if (orgId && auth?.user?.refresh_token) {
      doSync(orgId, auth.user.refresh_token)
    }
  }, [orgId, auth?.user])

  if (initialising) {
    return <FullScreenLoading />
  }

  return <></>
})

const SegenAfterConnectPage = React.memo(() => {
  const { inProgress } = useMsal()
  const history = useHistory()

  const autoSyncPriceStatus = useAutoSyncSegenPrice({
    onSyncCallback: () => {
      history.push('/shop')
    },
  })

  const initialising = useMemo(() => {
    return inProgress !== InteractionStatus.None || autoSyncPriceStatus !== 'finalized'
  }, [inProgress, autoSyncPriceStatus])

  if (initialising) {
    return <FullScreenLoading />
  }

  return <></>
})

const AfterConnectPage = (props) => {
  const distributor = props?.match?.params?.distributor
  const orgId = useSelector(authSelectors.getOrgId)

  useEffect(() => {
    logAmplitudeEvent('hardware_distributor_connection_completed', { supplier: distributor, orgId })
  }, [distributor, orgId])

  switch (distributor) {
    case 'city_plumbing':
      return <CityPlumbingAfterConnectPage />
    case 'segen':
      return <SegenAfterConnectPage />
    case 'hdm':
      return <HdmAfterConnectPage />
    case 'vtac_uk':
    case 'vtac_pl':
      return <VtacAfterConnectPage />
    default:
      return <HotTub />
  }
}

export default AfterConnectPage
