import { Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { isEmpty } from 'lodash'
import { FileIcon, MoreOptionsIcon, TrashOutlineIcon, UploadIcon } from 'opensolar-ui'
import DeleteConfirmModal from 'projectSections/sections/manage3/common/DeleteConfirmModal'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDropzone } from 'react-dropzone'
import { useStyles } from './fileUploadStyles'

const units: Record<string, number> = {
  B: 1,
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
}
const convertToBytes = (size: number, unit: string) => {
  // Ensure the unit is uppercase to handle case-insensitivity
  const upperUnit = unit.toUpperCase()

  if (Object.keys(units).includes(upperUnit)) {
    return size * units[upperUnit]
  } else {
    console.error(`Invalid unit: ${unit}`)
    return size * units['B']
  }
}

const convertFromBytes = (size: number, unit: string) => {
  const upperUnit = unit.toUpperCase()

  if (Object.keys(units).includes(upperUnit)) {
    return `${(size / units[upperUnit]).toFixed(2)} ${upperUnit}`
  } else {
    console.error(`Invalid unit: ${unit}`)
    return size * units['B']
  }
}

export interface FileType {
  file?: File
  name: string
  size: number
}
export interface FileUploadProps {
  keyName: string
  fileLinks: FileType[]
  description: string
  addFile: (file: File[]) => void
  deleteFile: (index: number) => void
  uploadFileSize: number
  unit: string
  acceptedFormats: string[]
  fileFormatIncorrectText?: string
  fileUploadContainerTitle?: string
  disabled?: boolean
  variant?: string
}
export const FileUpload = React.memo(
  ({
    keyName,
    deleteFile,
    fileLinks = [],
    addFile,
    description = '',
    uploadFileSize = 2, // 2 MB default max upload size
    unit = 'MB',
    acceptedFormats = ['png', 'jpg', 'jpeg'],
    fileFormatIncorrectText = 'Failed to upload the file. Format is incorrect.',
    fileUploadContainerTitle = 'Drag & drop files or ',
    disabled = false,
    variant = 'large',
  }: FileUploadProps) => {
    const translate = useTranslate()
    const classes = useStyles()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [error, setError] = useState<string>('')
    const onDrop = useCallback(
      async (acceptedFiles: File[], rejectedFiles: File[]) => {
        if (!isEmpty(rejectedFiles)) {
          setError(translate(`File size cannot be more than ${uploadFileSize}${unit}.`, { uploadFileSize, unit }))
        } else {
          const isFormatAccepted = acceptedFiles.every((file) =>
            acceptedFormats.some((format) => file.type.includes(format))
          )
          if (!isFormatAccepted) {
            setError(translate(`${fileFormatIncorrectText}`))
          } else {
            setError('')
            addFile(acceptedFiles)
            if (inputRef.current) {
              inputRef.current.value = ''
            }
          }
        }
      },
      [uploadFileSize, unit]
    )
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      maxSize: convertToBytes(uploadFileSize, unit),
    })
    const handleSelectFiles = (event: any) => {
      event.stopPropagation()
      inputRef.current = document.getElementById(`file-input-${keyName}`) as HTMLInputElement
      inputRef.current?.click()
    }
    return (
      <>
        <div className={classes.container}>
          {variant === 'large' ? (
            <div
              className={`${classes.dropZone} ${isDragActive ? classes.dragActive : ''} ${
                disabled ? classes.disabled : ''
              } ${!isEmpty(error) ? classes.dropZoneError : ''}`}
            >
              <div {...getRootProps()} onClick={(e) => e.preventDefault()}>
                <div className={classes.fileUploadContainer}>
                  <div>
                    <div
                      className={`${classes.iconContainer} ${!isEmpty(error) ? classes.errorBackground : ''} ${
                        isDragActive ? classes.dragActiveIcon : ''
                      }`}
                    >
                      <div className={classes.iconStyle}>
                        <UploadIcon
                          size={20}
                          color={
                            isDragActive ? COLOR_PALETTE.darkBlue2 : error ? COLOR_PALETTE.red2 : COLOR_PALETTE.darkGrey
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    {...getInputProps()}
                    id={`file-input-${keyName}`}
                    data-testid="input-upload-button"
                    accept={acceptedFormats.join(',')}
                  />
                  <Typography className={`${classes.text} ${classes.darkText}`}>
                    {translate(fileUploadContainerTitle)}
                    <span>
                      <Link
                        component="button"
                        variant="body1"
                        onClick={handleSelectFiles}
                        underline="none"
                        color="inherit"
                      >
                        {translate('browse files')}
                      </Link>
                    </span>
                  </Typography>
                </div>
                {!isEmpty(error) ? (
                  <Typography className={classes.errorText}>{translate(error)}</Typography>
                ) : (
                  <Typography className={`${classes.text} ${classes.miscellaneousText} ${classes.descriptionText}`}>
                    {translate(description)}
                  </Typography>
                )}
              </div>
            </div>
          ) : (
            <div
              {...getRootProps()}
              onClick={(e) => e.preventDefault()}
              className={`${classes.dropZoneSmall} ${isDragActive ? classes.dragActive : ''} ${
                disabled ? classes.disabled : ''
              } ${!isEmpty(error) ? classes.dropZoneError : ''}`}
            >
              <div>
                <div
                  className={`${classes.iconContainer} ${classes.smallContainer} ${
                    !isEmpty(error) ? classes.errorBackground : ''
                  } ${isDragActive ? classes.dragActiveIcon : ''}`}
                >
                  <div className={classes.iconStyle}>
                    <UploadIcon
                      size={20}
                      color={
                        isDragActive ? COLOR_PALETTE.darkBlue2 : error ? COLOR_PALETTE.red2 : COLOR_PALETTE.darkGrey
                      }
                    />
                  </div>
                </div>
              </div>
              <input
                {...getInputProps()}
                id={`file-input-${keyName}`}
                data-testid="input-upload-button"
                accept={acceptedFormats.join(',')}
              />
              <Typography className={`${classes.text} ${classes.darkText}`}>
                {translate(fileUploadContainerTitle)}
                <span>
                  <Link component="button" variant="body1" onClick={handleSelectFiles} underline="none" color="inherit">
                    {translate('browse files')}
                  </Link>
                </span>
              </Typography>
            </div>
          )}
        </div>
        {/* show files */}
        {fileLinks &&
          fileLinks.map((file, index) => (
            <div className={classes.fileNameContainer}>
              <ShowFiles file={file} deleteFile={deleteFile} key={`${file?.name}-file`} index={index} />
            </div>
          ))}
      </>
    )
  }
)

export interface ShowFilesProps {
  file: FileType
  deleteFile: (index: number) => void
  index: number
}

export const ShowFiles = ({ file, deleteFile, index }: ShowFilesProps) => {
  const classes = useStyles()
  const translate = useTranslate()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openMoreOptions, setOpenMoreOptions] = useState<boolean>(false)

  const handleDelete = () => {
    setOpenModal(false)
    deleteFile(index)
  }
  const closeMoreOptions = () => {
    setOpenModal(false)
    setOpenMoreOptions(false)
  }
  return (
    <div className={classes.file}>
      <div>
        <div className={classes.fileIconContainer}>
          <div className={classes.iconStyle}>
            <FileIcon size={20} color={COLOR_PALETTE.iconColor} />
          </div>
        </div>
      </div>
      <div className={classes.fileInfo}>
        <div>{file.name}</div>
        <div className={classes.miscellaneousText}>{convertFromBytes(file.size, 'MB')}</div>
      </div>
      <div>
        <div className={classes.moreOptionsIconContainer}>
          <div
            className={`${classes.iconStyle} ${classes.cursorPointer}`}
            onClick={() => setOpenMoreOptions(!openMoreOptions)}
          >
            <MoreOptionsIcon size={20} color={COLOR_PALETTE.black} />
          </div>
        </div>
        {openMoreOptions && (
          <div className={classes.deleteContainer} onClick={() => setOpenModal(true)}>
            <div className={classes.deleteIconContainer}>
              <TrashOutlineIcon color={'#BC1C1C'} />
            </div>
            <div className={classes.autoMarginRight}>{translate('Delete File')}</div>
          </div>
        )}
      </div>
      {openModal && (
        <DeleteConfirmModal
          handleDelete={handleDelete}
          open={openModal}
          setOpen={setOpenModal}
          title={translate('File')}
        />
      )}
    </div>
  )
}
