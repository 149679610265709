import { orgSelectors } from 'ducks/orgs'
import { StyledNavLinkProps } from 'layout/headers/elements/StyledNavLink'
import { useGetCashFlowNavVisible } from 'pages/cashFlow/utils'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'

type NavigationLinkType = {
  badgeSeverity?: StyledNavLinkProps['badgeSeverity']
  label: string
  key: string
  uiKey: string
}

const BASE_NAVIGATION_LINKS: Omit<NavigationLinkType, 'uiKey'>[] = [
  {
    key: 'projects',
    label: 'Projects',
  },
  {
    key: 'control',
    label: 'Control',
  },
]
export const HOME_NAVIGATION_LINK: Omit<NavigationLinkType, 'uiKey'> = {
  key: 'home',
  label: 'Home [App Navigation]',
}
const UI_KEY = 'header.nav.'

const useNavigationLinks = (): NavigationLinkType[] => {
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const cashFlowOnboardingStatus = useSelector(orgSelectors.getBlueSnapOnboardingStatus)
  const cashFlowEnabled = useGetCashFlowNavVisible()
  const isTopNav3Enabled = useFeatureFlag('top_nav_3', 'on')
  const { zone_org: zoneOrg } = useSelector(orgSelectors.getOrg) || {}

  const cashFlowBadgeSeverity = useMemo(() => {
    if (cashFlowOnboardingStatus === 'created' || cashFlowOnboardingStatus === null) {
      return 'info'
    }
    return undefined
  }, [cashFlowOnboardingStatus])

  return useMemo(
    () =>
      [
        !isTopNav3Enabled && HOME_NAVIGATION_LINK,
        ...BASE_NAVIGATION_LINKS,
        enableHardwareOrdering && {
          key: 'shop',
          label: 'Shop',
        },
        zoneOrg && {
          key: 'zone',
          label: zoneOrg.zone_title || 'Zone',
        },
        cashFlowEnabled && {
          badgeSeverity: cashFlowBadgeSeverity,
          key: 'cashflow',
          label: 'CashFlow',
        },
      ]
        .filter(Boolean)
        .map((navLink) => ({
          ...navLink,
          uiKey: `${UI_KEY}${navLink.key}`,
        })),
    [cashFlowEnabled, cashFlowBadgeSeverity, enableHardwareOrdering, zoneOrg]
  )
}

export default useNavigationLinks
