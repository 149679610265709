import { TextField } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { StudioPanelContext } from 'Designer/Panel'
import { PrimaryButton } from 'elements/button/GenericButton'
import { AlertTooltip } from 'elements/tooltip/AlertTooltip'
import { useTranslate } from 'ra-core'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { trimDecimalPlaces } from 'util/misc'

export type NumFieldPropType = {
  name: string
  value: number

  label?: string

  resettable?: boolean
  resetValue?: number

  // limits
  maxValue?: number
  minValue?: number

  // validation
  maxErrorMsg?: string
  minErrorMsg?: string

  // formatting
  maxDecimalPlaces?: number // n=0 means no decimal, n means n decimal places to show
  endAdornment?: string

  // access
  disabled?: boolean

  // styling
  muiStyleLabel?: boolean
  // overrides the styles of the
  wrapperStyles?: Partial<CSSProperties>
  fieldStyles?: Partial<CSSProperties>

  // callbacks
  onChange?: (newValue: number, rawEvent?: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (currentValue: number, rawEvent?: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (currentValue: number, rawEvent?: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (currentValue: number, rawEvent?: React.KeyboardEvent<HTMLInputElement>) => void

  // transform function
  transformFunc?: (value: number, str: string) => number // for when you want to convert/morph the raw value in real time
  variant?: 'filled' | 'standard' | 'outlined'
}

// @TODO replace the util func for parsing float inside utils/misc.ts with this one if safe to do so
const parseFloatFromString = (str: string): number => {
  const value = window.parseFloat(str)
  const rawEqualsParsed = str === value.toString()
  const isExponentialForm = str.indexOf('e') !== -1 // very crude way of checking for exponential form, but this will do for now
  if (!isNaN(value) && (isExponentialForm || rawEqualsParsed)) {
    return value
  } else {
    return NaN
  }
}

const DEFAULT_DECIMAL_LIMIT = 3

export const CustomNumberField = ({ ...props }: NumFieldPropType) => {
  const minValue = !props.minValue && props.minValue !== 0 ? Number.NEGATIVE_INFINITY : (props.minValue as number)
  const maxValue = !props.maxValue && props.maxValue !== 0 ? Number.POSITIVE_INFINITY : (props.maxValue as number)

  const maxDecimalPlaces = Number.isInteger(props.maxDecimalPlaces)
    ? (props.maxDecimalPlaces as number)
    : DEFAULT_DECIMAL_LIMIT

  const [value, setValue] = useState(trimDecimalPlaces(props.value, maxDecimalPlaces))

  const translate = useTranslate()
  const studioPanelContext = useContext(StudioPanelContext)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (focused) return
    setValue((_prev: string) => trimDecimalPlaces(props.value, maxDecimalPlaces))
  }, [props.value, maxDecimalPlaces, focused])

  /////////////////////////////
  //    aux functions
  /////////////////////////////
  const withinDecimalLimit = useCallback(
    (valueAsString: string) => {
      // this currently cannot detect if the given value string is in exponential form
      // for example, '10e-5' is equal to 0.0001
      if (valueAsString.indexOf('.') === -1) return true
      const decimalDigits = valueAsString.split('.')[1]
      return decimalDigits.length <= maxDecimalPlaces
    },
    [maxDecimalPlaces]
  )

  const withinMinMax = useCallback(
    (value: number) => {
      return value >= minValue && value <= maxValue
    },
    [minValue, maxValue]
  )

  const dispatchChange = useCallback(
    (value: number, rawEvent?: React.ChangeEvent<HTMLInputElement>) => {
      if (!isNaN(value) && withinMinMax(value)) {
        if (props.onChange) {
          props.onChange(value, rawEvent)
        }
      }
    },
    [props.onChange, props.transformFunc]
  )

  const getErrorMsgForValue = useCallback(
    (value: number): string | null => {
      if (value < minValue) {
        return props.minErrorMsg
          ? props.minErrorMsg
          : translate('Supply a value greater than %{value}.', { value: minValue })
      } else if (value > maxValue) {
        return props.maxErrorMsg
          ? props.maxErrorMsg
          : translate('Supply a value less than %{value}.', { value: maxValue })
      } else {
        return null
      }
    },
    [translate, props.minErrorMsg, props.maxErrorMsg, minValue, maxValue]
  )

  /////////////////////////////
  //    event handlers
  /////////////////////////////
  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value
      if (withinDecimalLimit(rawValue)) {
        setValue(rawValue)
        let parsedValue = parseFloatFromString(rawValue)
        if (props.transformFunc) parsedValue = props.transformFunc(parsedValue, rawValue)
        dispatchChange(parsedValue, event)
      }
    },
    [setValue, withinDecimalLimit, withinMinMax, dispatchChange]
  )

  const onInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      if (studioPanelContext) {
        markFieldInactive.call(studioPanelContext.context)
      }
      props.onBlur && props.onBlur(value, event)
    },
    [value, props.onBlur]
  )

  const onInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      props.onKeyDown && props.onKeyDown(value, event)
    },
    [value, props.onKeyDown]
  )

  const onInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      if (studioPanelContext) {
        markFieldActive.call(studioPanelContext.context, props.name, studioPanelContext.object)
      }
      props.onFocus && props.onFocus(value, event)
    },
    [value, props.onFocus]
  )

  const onResetBtnClick = useCallback(
    (_event) => {
      const resetValue = !props.resetValue && props.resetValue !== 0 ? 0 : props.resetValue
      setValue(resetValue)
      dispatchChange(resetValue)
    },
    [setValue, props.resetValue, withinMinMax, dispatchChange]
  )

  const errorMsg = getErrorMsgForValue(value)

  return (
    <div style={{ marginBottom: 15, ...props.wrapperStyles, display: 'flex' }}>
      {!props?.muiStyleLabel && props.label && <div style={{ width: 80, marginTop: 7 }}>{translate(props.label)}</div>}
      <div style={{ position: 'relative' }}>
        <TextField
          value={value}
          name={props.name}
          disabled={props.disabled || false}
          inputProps={{ min: minValue, max: maxValue }}
          InputProps={{ endAdornment: props.endAdornment }}
          onChange={onInputChange}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          onKeyDown={onInputKeyDown}
          error={!!errorMsg}
          style={{ width: 80, ...props.fieldStyles }}
          label={props.muiStyleLabel && props?.label ? props.label : null}
          type="number"
          variant={props?.variant}
        />
        {!!errorMsg && <AlertTooltip message={translate(errorMsg)} />}
      </div>

      {props.resettable && (
        <PrimaryButton
          disabled={props.disabled || false}
          labelWrapperStyle={{ padding: 0 }}
          label={translate('Reset')}
          wrapperStyle={{ marginLeft: 5, marginTop: -8 }}
          onClick={onResetBtnClick}
        />
      )}
    </div>
  )
}
