import { Divider, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import { COLOR_PALETTE } from 'elements/input/styles'
import { getOrderCost } from 'pages/ordering/utils'
import { useNotify, useTranslate } from 'ra-core'
import { memo, useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setAuthRedirection, storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import restClient from 'restClient'
import { CityPlumbingConnectUserButton } from 'services/cityPlumbing/CityPlumbingConnectUserButton'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import { useFeatureFlag } from 'util/split'

type Breakdown = {
  label: string
  value: string
  isTextBold: boolean
}

const ToolTipMessage = 'Create a job list with City Plumbing.'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    padding: 15,
    marginBottom: 15,
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    margin: '4px 0px 16px 0px',
    backgroundColor: '#000000',
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  textBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0px 16px 0px',
    gap: '16px',
  },
  shippingCalculationFooter: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.greyMid1,
  },
  connectFooter: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#0A090B',
  },
  loginLink: {
    fontSize: '14px',
    lineHeight: '22px',
    color: COLOR_PALETTE.darkBlue2,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
}))

const CityPlumbingCartActions = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const auth = useAuth()
  const history = useHistory()
  const notify = useNotify()
  const enable_place_order = useFeatureFlag('enable_city_plumbing_checkout', 'on')
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const orderableLineItems = useSelector(orderSelectors.getOrderableLineItems)
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const { isAuthenticated } = useDistributorAuthenticationStatus({ orgId, distributor: 'city_plumbing' })
  const cost = getOrderCost(orderableLineItems)
  const vatPercentage = 20

  const breakdown: Breakdown[] = [
    {
      label: 'Total before Tax:',
      value: `${currencySymbol} ${formatCurrency(cost)}`,
      isTextBold: true,
    },
    {
      label: `VAT (${vatPercentage}%):`,
      value: `${currencySymbol} ${formatCurrency(cost * (vatPercentage / 100))}`,
      isTextBold: false,
    },
    { label: 'Estimated Shipping Cost', value: 'TBC', isTextBold: false },
  ]

  useEffect(() => {
    return auth?.events.addAccessTokenExpiring(() => {
      auth?.signinSilent()
    })
  }, [])

  const onClickLoginNow = () => {
    console.log('onClickLoginNow: ')
    setAuthRedirection({
      type: 'AUTH_SUCCESS_REDIRECT',
      redirect: '/shop/after_connect/city_plumbing?redirect_url=shop_cart_page',
    })
    storeAuthRedirectionToLocalStorage()

    auth?.signinRedirect()
  }

  const handleReviewAndPlaceOrder = () => {
    logAmplitudeEvent('hardware_place_order_cta_clicked', {
      distributor: 'city_plumbing',
      source: 'shopping_cart',
      codes: orderableLineItems.map((lineItem) => lineItem.code),
    })
    history.push(`/shop/cart/city_plumbing`)
  }

  const handleCreateJobList = async (event) => {
    event.preventDefault()

    logAmplitudeEvent('hardware_review_order_cta_clicked', {
      distributor: 'city_plumbing',
      source: 'shopping_cart',
      codes: orderableLineItems.map((lineItem) => lineItem.code),
    })
    console.log('lineItems', orderableLineItems)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/_orders/city_plumbing/create/',
      data: {
        items: orderableLineItems,
        project_ids: orderSelectors.getProjectListByLineItems(orderableLineItems),
      },
    })
      .then((response) => {
        console.log('send job. got data', response.data)
        if (!response.data?.status) {
          notify(response.data?.result.error)
          return
        }

        const jobListId = response.data['result']['jobList']['id']
        history.push(`/shop/cart/city_plumbing/${jobListId}`)
      })
      .catch((error) => {
        if (error) {
          console.warn('Error in City Plumbing Order', error)
        }
      })
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.textWrapper}>
        <Typography variant="h6" gutterBottom className={classes.textWrapper}>
          {translate('shopping_cart_action_subtotal', { smart_count: orderableLineItems.length })}
        </Typography>
      </div>
      <div className={classes.textWrapper} style={{ marginTop: '24px' }}>
        <Typography gutterBottom className={classes.text}>
          Subtotal:
        </Typography>
        <Typography gutterBottom className={classes.text}>
          {`${currencySymbol} ${formatCurrency(cost)}`}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <>
        {breakdown.map((item: Breakdown) => (
          <div className={classes.textWrapper}>
            <Typography gutterBottom className={item.isTextBold ? classes.textBold : ''}>
              {item.label}
            </Typography>
            <Typography gutterBottom className={classes.text}>
              {item.value}
            </Typography>
          </div>
        ))}
      </>
      {enable_place_order && (
        <>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              fullWidth
              disabled={orderableLineItems.length === 0}
              onClick={handleReviewAndPlaceOrder}
            >
              {isAuthenticated ? 'Review and Place Order' : 'Review and Place Order as Guest'}
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
            <Typography className={classes.shippingCalculationFooter}>*Shipping calculated on the next page</Typography>
          </div>
          {!isAuthenticated && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography gutterBottom className={classes.connectFooter}>
                <span>Already have an account? </span>
                <span className={classes.loginLink} onClick={onClickLoginNow}>
                  Login now
                </span>
              </Typography>
            </div>
          )}
        </>
      )}
      {!enable_place_order && (
        <Tooltip title={ToolTipMessage} interactive={true} arrow placement="top">
          {isAuthenticated ? (
            <Button
              color="primary"
              disabled={orderableLineItems.length === 0}
              fullWidth={true}
              onClick={handleCreateJobList}
            >
              Create City Plumbing Job List
            </Button>
          ) : (
            <CityPlumbingConnectUserButton
              layout={null}
              allowOrdering={false}
              variant={'contained'}
              trackSource={'city_plumbing_page'}
            />
          )}
        </Tooltip>
      )}
    </div>
  )
}

export default memo(CityPlumbingCartActions)
