import { Grid, Typography } from '@material-ui/core'
import Button from 'elements/button/Button'
import ValidityChip from 'elements/chip/ValidityChip'
import ProjectName from 'elements/ProjectName'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { SearchingContext } from 'pages/inventory/dashboard/InventoryDashboard'
import AddMissingItemButton from 'pages/inventory/forecast/modal/AddMissingItemButton'
import useConfirmProjectsTransfer from 'pages/inventory/transfer/hooks/useConfirmProjectsTransfer'
import { memo, useContext, useEffect, useState } from 'react'
import { SelectDropDown } from 'resources/projects/projectsList/SelectDropDown'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes } from 'types/selectComponent'
import { WorkflowStageType } from 'types/workflows'
import InstallationDataInput from '../../../ordering/projectTable/table/InstallationDataInput'
import { mapForecastStatusToValidity } from '../constants'
import ForecastDateInput from '../fields/ForecastDateInput'
import ForecastToggle from '../fields/ForecastToggle'
import useRemoveProjectForecast from '../hooks/useRemoveProjectForecast'
import type { ForecastedProjectInventory } from '../type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  gridContainer: {
    minHeight: 62,
    borderBottom: '1px solid #e7e7e7',
  },
  customerName: {
    color: theme.greyMid1,
  },
  removeButton: {
    textDecoration: 'underline',
    color: theme.greyMid1,
  },
}))

export const HistoricalProjectRow = ({
  forecastedProjectInventory,
  refreshInventoryDashboard,
  stageOptions,
}: {
  forecastedProjectInventory: ForecastedProjectInventory
  refreshInventoryDashboard(): void
  stageOptions?: WorkflowStageType[]
}) => {
  const classes = useStyles()
  const { confirmProjectsTransfer } = useConfirmProjectsTransfer()
  const { removeProject } = useRemoveProjectForecast()
  const [isTimeout, setIsTimeout] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  let timeoutId

  useEffect(() => {
    if (isTimeout) refreshInventoryDashboard()
  }, [isTimeout])

  const handleTimer = () => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      setIsTimeout(true)
    }, 3000)
  }

  return (
    <>
      <Grid container xs={12} alignItems={'center'} justify={'center'} className={classes.gridContainer}>
        <Grid item xs={1} style={{ paddingLeft: 10 }}>
          <ProjectName
            business_name={forecastedProjectInventory.project.business_name}
            address={forecastedProjectInventory.project.address}
            id={forecastedProjectInventory.project.id}
          />
          {forecastedProjectInventory.project.customer_name.length > 0 && (
            <Typography variant="caption" className={classes.customerName}>
              {forecastedProjectInventory.project.customer_name[0]}
            </Typography>
          )}
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <SelectDropDown
            choices={stageOptions}
            source="workflow.active_stage_id"
            submitSource="active_stage_id"
            record={forecastedProjectInventory.project}
            isCategory={true}
            autoTick={true}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={2}>
          <InstallationDataInput
            projectId={forecastedProjectInventory.project_id}
            installationDate={forecastedProjectInventory.project.installation_date}
            onSuccess={refreshInventoryDashboard}
            trackSource={'inventory_install_date_updated'}
            isHistorical={true}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={2}>
          <ForecastDateInput
            projectId={forecastedProjectInventory.project_id}
            pickupDate={forecastedProjectInventory.project.pickup_date}
            onSuccess={refreshInventoryDashboard}
            disabled={!!forecastedProjectInventory.project.installation_date}
            isHistorical={true}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}></Grid>
        <Grid container alignItems="center" justify="center" item xs={1}></Grid>
        <Grid container alignItems="center" justify="center" item xs={1}></Grid>
        <Grid container alignItems="center" justify="flex-end" item xs={1}>
          {forecastedProjectInventory.project.confirm_needed && (
            <InfoTooltip title="The install date has passed for these projects. Confirm project to create outbound transfer; Update install date to reschedule, or remove it from forecast." />
          )}
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          {forecastedProjectInventory.project.confirm_needed && (
            <Button
              variant="contained"
              size="small"
              disabled={disabled}
              onClick={async () => {
                setDisabled(true)
                handleTimer()
                await confirmProjectsTransfer({ projectIds: [forecastedProjectInventory.project.id] })
              }}
            >
              Confirm
            </Button>
          )}
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          {forecastedProjectInventory.project.confirm_needed && (
            <Button
              variant="text"
              size="small"
              className={classes.removeButton}
              onClick={async () => {
                await removeProject({ projectId: forecastedProjectInventory.project.id })
                refreshInventoryDashboard()
              }}
            >
              Remove
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  )
}

type ForecastTableRowPropsType = {
  forecastedProjectInventory: ForecastedProjectInventory
  onStatusClicked(componentType: ComponentTypes): void
  refreshInventoryDashboard(): void
  stageOptions?: WorkflowStageType[]
}

const ForecastTableRow = ({
  forecastedProjectInventory,
  onStatusClicked,
  refreshInventoryDashboard,
  stageOptions,
}: ForecastTableRowPropsType) => {
  const classes = useStyles()
  const { searchState } = useContext(SearchingContext)
  const enable = forecastedProjectInventory.project.forecast
  const isItemsMissing = forecastedProjectInventory.items_required_allocated.some(
    (item) => item.validity === 'not_available' || item.validity === 'below_min'
  )
  const allLineItems = forecastedProjectInventory.items_required_allocated.map((item) => {
    return {
      component_type: item.component_type,
      missingItems: item.required - item.allocated,
      code: item.code,
      allocated: item.allocated,
      required: item.required,
      validity: item.validity,
    }
  })

  const getTooltipTitle = (componentType) => {
    if (!enable) return ''
    switch (mapForecastStatusToValidity(forecastedProjectInventory[componentType])) {
      case 'valid':
        return "This component is forecasted to be available for the project's forecast/installation date"
      case 'error':
        return "This component is not forecasted to be available for the project's forecast/installation date"
      case 'warning':
        return 'This component will be below your set minimum'
      default:
        return ''
    }
  }

  return (
    <>
      <Grid container xs={12} alignItems={'center'} justify={'center'} className={classes.gridContainer}>
        <Grid item xs={1} style={{ paddingLeft: 10 }}>
          <ProjectName
            business_name={forecastedProjectInventory.project.business_name}
            address={forecastedProjectInventory.project.address}
            id={forecastedProjectInventory.project.id}
          />
          {forecastedProjectInventory.project.customer_name.length > 0 && (
            <Typography variant="caption" className={classes.customerName}>
              {forecastedProjectInventory.project.customer_name[0]}
            </Typography>
          )}
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <SelectDropDown
            choices={stageOptions}
            source="workflow.active_stage_id"
            submitSource="active_stage_id"
            record={forecastedProjectInventory.project}
            isCategory={true}
            autoTick={true}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={2}>
          <InstallationDataInput
            projectId={forecastedProjectInventory.project_id}
            installationDate={forecastedProjectInventory.project.installation_date}
            onSuccess={refreshInventoryDashboard}
            trackSource={'inventory_install_date_updated'}
            isHistorical={false}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={2}>
          <ForecastDateInput
            projectId={forecastedProjectInventory.project_id}
            pickupDate={forecastedProjectInventory.project.pickup_date}
            onSuccess={refreshInventoryDashboard}
            disabled={!!forecastedProjectInventory.project.installation_date}
            isHistorical={false}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <ForecastToggle
            forecasted={enable}
            projectId={forecastedProjectInventory.project_id}
            onSuccess={refreshInventoryDashboard}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <ValidityChip
            label={forecastedProjectInventory.module_quantity}
            validity={enable ? mapForecastStatusToValidity(forecastedProjectInventory.modules) : 'default'}
            onClick={() => onStatusClicked('module')}
            title={searchState ? 'Forecast unavailable during search' : getTooltipTitle('modules')}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <ValidityChip
            label={forecastedProjectInventory.inverter_quantity}
            validity={enable ? mapForecastStatusToValidity(forecastedProjectInventory.inverters) : 'default'}
            onClick={() => onStatusClicked('inverter')}
            title={searchState ? 'Forecast unavailable during search' : getTooltipTitle('inverters')}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <ValidityChip
            label={forecastedProjectInventory.battery_quantity}
            validity={enable ? mapForecastStatusToValidity(forecastedProjectInventory.batteries) : 'default'}
            onClick={() => onStatusClicked('battery')}
            title={searchState ? 'Forecast unavailable during search' : getTooltipTitle('batteries')}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <ValidityChip
            label={forecastedProjectInventory.other_quantity}
            validity={enable ? mapForecastStatusToValidity(forecastedProjectInventory.others) : 'default'}
            onClick={() => onStatusClicked('other')}
            title={searchState ? 'Forecast unavailable during search' : getTooltipTitle('others')}
          />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          {enable && isItemsMissing && (
            <AddMissingItemButton allLineItems={allLineItems} project={forecastedProjectInventory.project} />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default memo(ForecastTableRow)
