import { styled } from 'opensolar-ui'
import { TagData } from 'types/projects'
import { StyledChip } from '../styles'

interface PropTypes {
  tags: TagData[]
}

const ChipWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const TagsDisplay: React.FC<PropTypes> = ({ tags }) => {
  return !!tags?.length ? (
    <ChipWrapper>
      {tags.slice(0, 2).map((tag) => (
        <StyledChip label={tag.title} variant="outlined" />
      ))}
      {tags.length > 2 && <>+{tags.length - 2}</>}
    </ChipWrapper>
  ) : (
    <>{'－'}</>
  )
}

export default TagsDisplay
