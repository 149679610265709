import { usePromos } from 'persistentContent/inAppPromo/hooks/usePromos'
import { useEffect, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import { generateUUID } from 'util/misc'
import { RecommendBatterySetType, RecommendedBatterySetsRequestParams } from '../utility/types'

type PropsType = {
  params: RecommendedBatterySetsRequestParams
  projectId: number
  systemUuid: string
}

const useFetchRecommendedBatterySets = ({
  params,
  projectId,
  systemUuid,
}: PropsType): {
  isLoading: boolean
  recommendedBatterySets: RecommendBatterySetType[]
} => {
  const [isLoading, setIsLoading] = useState(true)
  const [recommendedBatterySets, setRecommendedBatterySets] = useState([])

  const { promos, state: promosLoadingState } = usePromos('sponsored_content')
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // Create dict mapping sponsored battery codes to weighting
  const sponsoredBatteryCodesWeightMap = {}
  promos
    .filter((promo) => promo.sponsoredContent!.component_codes)
    .forEach(({ sponsoredContent }) => {
      sponsoredContent!.component_codes.forEach(
        (code) => (sponsoredBatteryCodesWeightMap[code] = sponsoredContent!.weighting)
      )
    })

  if (Object.keys(sponsoredBatteryCodesWeightMap).length) {
    // TODO: move this map to BE once this PR is merged https://github.com/open-solar/sunsuite/pull/8215
    params['sponsored_batteries'] = sponsoredBatteryCodesWeightMap
  }

  useEffect(() => {
    if (promosLoadingState !== 'not-loaded') {
      dataProvider
        .CUSTOM_POST(
          'recommend_battery_sets',
          {
            url: `projects/${projectId}/systems/${systemUuid}/recommend_battery_sets/`,
            data: params,
          },
          {
            onSuccess: ({ data }) => {
              setIsLoading(false)
              setRecommendedBatterySets(
                data.map((batterySet) => ({
                  ...batterySet,
                  batteries: batterySet.batteries.map((battery) => ({ ...battery, type: 'battery' })),
                  id: generateUUID(),
                }))
              )
            },
          }
        )
        .catch((error) => {
          setIsLoading(false)
          notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
        })
    }
  }, [promosLoadingState])

  return { isLoading, recommendedBatterySets }
}

export default useFetchRecommendedBatterySets
