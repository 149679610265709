import { makeStyles } from '@material-ui/core'
import { Theme } from 'types/themes'
import { COLOR_PALETTE } from '../styles'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    stageRow: {
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      height: 60,
    },
    btnContainer: {
      flexGrow: 1,
      display: 'flex',
      position: 'relative',
      flexBasis: '100%',
    },
    baseStageBtn: {
      fontSize: 14,
      height: '50px !important',
      flexGrow: 1,
    },
    stageBtn: {
      border: 'none !important',
      margin: '0 5px !important',
      '&:before': {
        content: `''`,
        position: 'absolute',
        top: 0,
        height: '50%', // 0.02% to prevent border tiny gap
        width: '100%',
        transform: 'skew(25deg, 0deg)',
        borderRadius: 3,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        boxSizing: 'border-box',
      },
      '&:after': {
        content: `''`,
        position: 'absolute',
        bottom: 0,
        height: '50%',
        width: '100%',
        transform: 'skew(-25deg, 0deg)',
        borderRadius: 3,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxSizing: 'border-box',
      },
    },
    stageActive: {
      color: COLOR_PALETTE.darkBlue + '!important',
      '&:before': {
        backgroundColor: COLOR_PALETTE.blue,
        border: `1px solid ${COLOR_PALETTE.darkBlue}`,
        borderBottom: 'none !important',
      },
      '&:after': {
        backgroundColor: COLOR_PALETTE.blue,
        border: `1px solid ${COLOR_PALETTE.darkBlue}`,
        borderTop: 'none !important',
      },
    },
    pastStage: {
      color: '#fff !important',
      '&:before, &:after': {
        backgroundColor: COLOR_PALETTE.blue3,
        border: '1px solid ' + COLOR_PALETTE.blue3 + ' !important',
      },
    },
    futureStage: {
      '&:before, &:after': {
        backgroundColor: COLOR_PALETTE.lightGrey,
        border: '1px solid ' + COLOR_PALETTE.lightGrey + ' !important',
      },
    },
    otherWrapper: {
      marginLeft: 'auto',
      padding: '0 10px',
    },
    otherBtn: {
      color: COLOR_PALETTE.iconColor + ' !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ' + COLOR_PALETTE.lightGrey5,
      },
    },
    otherActive: {
      color: COLOR_PALETTE.darkBlue + ' !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    activeHighlight: {
      backgroundColor: COLOR_PALETTE.blue + ' !important',
      border: `1px solid ${COLOR_PALETTE.darkBlue} !important`,
    },
    stageLabel: {
      zIndex: 2,
      paddingLeft: 6,
      maxHeight: 50,
      overflow: 'hidden',
      lineHeight: '125%',
    },
    firstStageLabel: {
      paddingLeft: 0,
    },
    edgeBlock: {
      position: 'absolute',
      width: 25,
      height: '100%',
      boxSizing: 'border-box',
      zIndex: 1,
      borderRadius: 3,
      '&.start': {
        left: 0,
      },
      '&.end': {
        right: -1,
      },
    },
    blockActive: {
      backgroundColor: COLOR_PALETTE.blue,
      border: `1px solid ${COLOR_PALETTE.darkBlue}`,
      '&.start': {
        borderRight: 'none',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&.end': {
        borderLeft: 'none',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
    },
    blockFuture: {
      backgroundColor: COLOR_PALETTE.lightGrey,
    },
    blockPast: {
      backgroundColor: COLOR_PALETTE.blue3,
    },
    menuItem: {
      fontSize: 14,
      padding: 16,
      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: 'unset',
      },
      '&.MuiListItem-root.Mui-selected:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  }),
  { name: 'WorkflowStageStepper' }
)
