import { format as dateFnsFormat, parseISO as dateFnsParse } from 'date-fns'
import * as dateFnsLocale from 'date-fns/locale'
import dayjs from 'dayjs'
import moment, { MomentInput } from 'moment'
import { useLocale, useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { fullMonthNames } from './misc'

export type DateFormatVariant = 'date' | 'time' | 'datetime'
export type DateFormatOpts = { locale: string; long?: boolean; variant?: DateFormatVariant }
export type DateFormatHookOpts = { locale?: string; long?: boolean; variant?: DateFormatVariant }

export function getDateFormat_date(opts: DateFormatOpts): string {
  if (opts.locale === 'en_US') {
    return opts.long ? 'MMMM dd, yyyy' : 'MM/dd/yyyy'
  } else {
    return opts.long ? 'dd MMMM yyyy' : 'dd/MM/yyyy'
  }
}
export function getDateFormat_time(opts: DateFormatOpts): string {
  if (opts.locale === 'en_US') {
    return 'h:mm a'
  } else {
    return 'HH:mm'
  }
}
export function getDateFormat_datetime(opts: DateFormatOpts): string {
  return `${getDateFormat_date(opts)} ${getDateFormat_time(opts)}`
}

export function getDateFormat(opts: DateFormatOpts): string {
  const variant = opts.variant || 'datetime'
  switch (variant) {
    case 'date':
      return getDateFormat_date(opts)
    case 'time':
      return getDateFormat_time(opts)
    case 'datetime':
      return getDateFormat_datetime(opts)
  }
}

export function useDateFormatter(opts?: DateFormatHookOpts): (date: string | undefined) => string {
  const globalLocale = useLocale()
  const locale = opts?.locale || globalLocale
  const format = getDateFormat({
    ...(opts || {}),
    locale,
  })
  return (date) => {
    if (!date) return ''
    // return moment(date).format(format)
    return dateFnsFormat(dateFnsParse(date), format, { locale: getDateFnsLocale(locale) })
  }
}

function getDateFnsLocale(locale: string): Object | undefined {
  locale = locale.replace('_', '')
  return dateFnsLocale[locale]
}

export function useDateFormatted(date: string | undefined, opts?: DateFormatHookOpts): string {
  const formatter = useDateFormatter(opts)
  return useMemo(() => formatter(date), [date, formatter])
}

// Generates '5 days ago' style sentances for a given date
export function useTimeSinceText(date: MomentInput | undefined): string {
  const translate = useTranslate()
  if (!date) return ''

  const eventDate = moment(date)
  const rightNow = moment()
  const minuteDiff = rightNow.diff(eventDate, 'minutes')
  if (Math.abs(minuteDiff) < 60) {
    if (minuteDiff === 0) {
      return translate('Just now')
    }
    if (minuteDiff > 0) {
      const timeframe = minuteDiff === 1 ? translate('minute ago') : translate('minutes ago')
      return `${minuteDiff} ${timeframe}`
    } else {
      const timeframe = minuteDiff === -1 ? translate('minute') : translate('minutes')
      return `${translate('in')} ${minuteDiff * -1} ${timeframe}`
    }
  }
  const hourDiff = rightNow.diff(eventDate, 'hours')
  if (Math.abs(hourDiff) < 24) {
    if (hourDiff >= 0) {
      const timeframe = hourDiff === 1 ? translate('hour ago') : translate('hours ago')
      return `${hourDiff} ${timeframe}`
    } else {
      const timeframe = hourDiff === -1 ? translate('hour') : translate('hours')
      return `${translate('in')} ${hourDiff * -1} ${timeframe}`
    }
  }
  const dayDiff = rightNow.diff(eventDate, 'days')
  if (Math.abs(dayDiff) < 180) {
    if (dayDiff >= 0) {
      const timeframe = dayDiff === 1 ? translate('day ago') : translate('days ago')
      return `${dayDiff} ${timeframe}`
    } else {
      const timeframe = dayDiff === -1 ? translate('day') : translate('days')
      return `${translate('in')} ${dayDiff * -1} ${timeframe}`
    }
  }
  const monthsDiff = rightNow.diff(eventDate, 'months')
  if (monthsDiff >= 0) return `${monthsDiff} ${translate('months ago')}`
  else return `${translate('in')} ${monthsDiff * -1} ${translate('months')}`
}

/**
 * returns true if input is a valid ISO 8601 string
 */
export const getIsValidISOString = (input: string) => {
  if (!input || typeof input !== 'string') return false
  return moment(input, moment.ISO_8601, true).isValid()
}

/**
 * Converts a date string in ISO 8601 format into a JS Date object
 */
export const convertISOStringToDate = (input: string) => {
  if (!input || !getIsValidISOString(input)) return undefined
  const asDate = moment(input)
  return new Date(asDate.year(), asDate.month() - 1, asDate.date())
}

//UI 3.0 - Possibly replace existing useDateFormatter if format is used anywhere else
export const formatDate = (date, withTime = false) => {
  if (!date) return ''
  const formatted = new Date(date)
  const day = formatted.getDate() < 10 ? `0${formatted.getDate()}` : formatted.getDate()
  return (
    `${day} ${fullMonthNames[formatted.getMonth()]} ${formatted.getFullYear()} ` +
    (withTime ? formatted.toLocaleTimeString() : '')
  )
}

export function getDateTimeFormat(opts: DateFormatOpts): string {
  return 'dd MMMM yyyy, h:mm a'
}

//Convert input value to valid datepicker object
export const parseInputValue = (input, isVersion2 = false) => {
  if (!input) return null
  let dateObj = input instanceof Date ? input : new Date(input)
  if (dateObj && isNaN(dateObj.getTime())) {
    console.error(`Invalid date: ${input}`)
    return null
  }
  if (isVersion2) return dayjs(input)
  return dateObj
}

//Ensure a JS Date object is returned
export const parseDateObject = (date): Date | null => {
  if (!date || isNaN(date)) return null
  if (date instanceof Date) return date
  return new Date(date)
}
