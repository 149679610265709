import { makeStyles } from '@material-ui/core/styles'
import { CloseRounded } from '@material-ui/icons'
import UserIcon from 'elements/UserIcon'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import { RoleType } from 'types/roles'
import { Theme } from 'types/themes'
import CustomDropdownInput from '../../../common/CustomDropdownInput'
import { COLOR_PALETTE, getProjectSectionStyles } from '../../../styles'

const useStyles = makeStyles((theme: Theme) => ({
  userRow: {
    marginTop: 10,
    marginBottom: 20,
    flexWrap: 'wrap',
    rowGap: 10,
    '& p': {
      margin: 0,
      marginLeft: 10,
      marginRight: 'auto',
      '& span': {
        fontSize: 12,
        color: COLOR_PALETTE.darkGrey,
      },
    },
  },
  actionLabel: {
    color: COLOR_PALETTE.darkRed,
    '& .MuiSvgIcon-root': {
      height: 20,
      marginRight: 5,
    },
  },
  roleInput: {
    minWidth: '220px',
  },
}))
interface PropTypes {
  member: RoleType
  role: string
  source: string
  roleChoices: { title: string; id: string }[]
}

const ProjectTeamMember: React.FC<PropTypes> = ({ member, role, roleChoices, source }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const sectionStyles = getProjectSectionStyles()

  const handleRemove = (role) => {
    form.change('internalTeam', [...formValues.internalTeam.filter((x) => x.projectRoleId !== role)])
  }
  const handleRoleChange = (newRole) => {
    if (newRole) form.change(`${source}.projectRoleId`, newRole)
  }

  return (
    <div className={`${sectionStyles.row} ${classes.userRow}`}>
      <UserIcon user={member} />
      <p>
        {member.first_name} {member.family_name} {(member.first_name || member.family_name) && <br />}{' '}
        <span>{member.email || member.user_email}</span>
      </p>
      <CustomDropdownInput
        source={`${source}.projectRoleId`}
        onChange={handleRoleChange}
        className={classes.roleInput}
        variant="outlined"
        choices={roleChoices}
        actions={[
          {
            label: (
              <div className={`${classes.actionLabel} ${sectionStyles.row}`}>
                <CloseRounded />
                {translate('Remove Team Member')}
              </div>
            ),
            onClick: (e) => {
              handleRemove(role)
            },
          },
        ]}
      />
    </div>
  )
}

export default ProjectTeamMember
