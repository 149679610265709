import { Divider, Grid, Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { clearLineItems, orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import { getOrderCost } from 'pages/ordering/utils'
import { useNotify } from 'ra-core'
import { memo, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import CheckoutPageLayout from '../../../PageLayout'
import CheckoutTitle from '../../CheckoutTitle'
import { CheckoutPresenterContext, ShippingDetailContext, ShippingFeeFetchContext } from './CheckoutProvider'
import OrderSummary from './OrderSummary'
import ReviewItems from './ReviewItems'
import ShippingAddress from './ShippingAddress'
import ShippingMethod from './ShippingMethod'
import { useSolarJuiceStyles } from './style'
import { ShippingDetailType } from './type'

type MainContentProps = {
  totalCost: number
  showChangeShippingAddress: boolean
  onChangeShowShippingAddress: (value: boolean) => void
  handlePlaceOrder: () => void
}

const MainContent = memo(
  ({ totalCost, showChangeShippingAddress, onChangeShowShippingAddress, handlePlaceOrder }: MainContentProps) => {
    const usingCustomPricing = useSelector(orderSelectors.getIsCustomPricing)
    const solarJuiceClasses = useSolarJuiceStyles()
    const currencySymbol = useSelector((state: any) => currencySymbolForCountry(orgSelectors.getOrgIso2(state)))
    const isFetchingShippingFee = useContext(ShippingFeeFetchContext)
    return (
      <Grid container>
        <Grid item xs={12}>
          <Divider style={{ marginTop: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <ShippingAddress
            showChangeShippingAddress={showChangeShippingAddress}
            onChangeShowShippingAddress={onChangeShowShippingAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ShippingMethod />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ReviewItems />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" justify="space-between" style={{ padding: '20px 30px' }}>
            <Grid container className={solarJuiceClasses.placeOrderCard}>
              <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Button color="primary" onClick={handlePlaceOrder} fullWidth disabled={isFetchingShippingFee}>
                  Place Order
                </Button>
              </Grid>
              <Grid item xs={10} style={{ display: 'flex', flexDirection: 'column', paddingLeft: '40px' }}>
                <Typography variant="h6" className={solarJuiceClasses.fontWeightMedium}>
                  <span>Order total: </span>
                  <span className={solarJuiceClasses.validColor}>
                    {usingCustomPricing ? 'TBC' : `${currencySymbol} ${formatCurrency(totalCost)}`}
                  </span>
                </Typography>
                <Typography className={solarJuiceClasses.font14}>
                  <span>By placing your order, you agree to Solar Outlet’s </span>
                  <a href="https://solarjuice.com.au/terms-conditions/" style={{ color: '#1751D0' }}>
                    Privacy Policy
                  </a>
                  <span> and </span>
                  <a href="https://solarjuice.com.au/terms-conditions/" style={{ color: '#1751D0' }}>
                    Conditions of Use
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
)

const Checkout = () => {
  const history = useHistory()
  const notify = useNotify()
  const dispatch = useDispatch()
  const usingCustomPricing = useSelector(orderSelectors.getIsCustomPricing)
  const checkoutPresenter = useContext(CheckoutPresenterContext)
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const shippingDetail: ShippingDetailType = useContext(ShippingDetailContext)
  const [showChangeShippingAddress, setShowChangeShippingAddress] = useState<boolean>(false)
  const subTotalCost = usingCustomPricing ? 0 : getOrderCost(lineItems)
  const projectIds = orderSelectors.getProjectListByLineItems(lineItems)
  const shippingFee: number = useMemo(
    () => (typeof shippingDetail.fee === 'string' ? 0 : shippingDetail.fee ? shippingDetail.fee : 0),
    [shippingDetail.fee]
  )
  const tax: number = useMemo(() => subTotalCost * 0.1, [subTotalCost])
  const totalCost: number = useMemo(() => subTotalCost + shippingFee + tax, [subTotalCost, shippingFee, tax])

  const handlePlaceOrder = async () => {
    if (showChangeShippingAddress) {
      notify('Please save any changes to your shipping address to continue with your order.', 'warning')
      return
    }
    logAmplitudeEvent('hardware_place_order_cta_clicked', {
      distributor: 'outlet',
      source: 'solar_juice_checkout',
      codes: lineItems.map((lineItem) => lineItem.code),
    })
    const orderId = await checkoutPresenter?.placeOrder(
      shippingDetail,
      { tax, shippingFee, subTotalCost, totalCost },
      projectIds,
      usingCustomPricing
    )
    if (orderId) {
      dispatch(clearLineItems())
      history.push(`/shop/order_confirmation/${orderId}`)
    }
  }

  return (
    <CheckoutPageLayout
      title={<CheckoutTitle />}
      mainContent={
        <MainContent
          totalCost={totalCost}
          showChangeShippingAddress={showChangeShippingAddress}
          onChangeShowShippingAddress={setShowChangeShippingAddress}
          handlePlaceOrder={handlePlaceOrder}
        />
      }
      sideAction={
        <OrderSummary
          cost={subTotalCost}
          totalBeforeTax={subTotalCost + shippingFee}
          tax={tax}
          totalCost={totalCost}
          handlePlaceOrder={handlePlaceOrder}
        />
      }
    />
  )
}

export default memo(Checkout)
