import { OpenSolarThemeType } from 'Themes'
import { Button, Chip, Table, TableBody, TableCell, TableHead, TableRow } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import UploadDocumentDialog from './UploadDocumentDialog'
import { BlueSnapDocumentStatusMapping, BlueSnapDocumentTypeMapping, BlueSnapOnboardingDocument } from './types'

type PropTypes = {
  documents: BlueSnapOnboardingDocument[]
  isLoading: boolean
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 15px',
    overflowX: 'auto',
  },
  tableStyles: {
    '& .MuiTable-root': {
      border: '1px solid #E7E7E7 !important',
    },
    '& .MuiTableHead-root': {
      backgroundColor: theme.greyLight3,
      padding: '8px 10px',
      '& .MuiTableCell-root': {
        whiteSpace: 'nowrap',
      },
    },
    '& .MuiTableHead-root, & .MuiTableBody-root': {
      border: '1px solid #E7E7E7 !important',
    },
  },
  tableLoader: {
    '& .MuiTableCell-root': {
      padding: '0px',
    },
  },
  uploadDocumentBtn: {
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
    },
  },
  chipSuccess: {
    ...theme.ValidityPalette.valid,
  },
  chipError: {
    ...theme.ValidityPalette.error,
  },
}))

const DocumentTable: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState<BlueSnapOnboardingDocument | undefined>()
  const { documents, isLoading } = props

  const handleUploadDialogOpen = (document) => {
    setSelectedDocument(document)
    setShowUploadDialog(true)
  }

  return (
    <div className={classes.wrapper}>
      <Table className={classes.tableStyles}>
        <TableHead>
          <TableRow>
            <TableCell>{translate('Document Type')}</TableCell>
            <TableCell>{translate('Description')}</TableCell>
            <TableCell>{translate('Notes')}</TableCell>
            <TableCell>{translate('Status')}</TableCell>
            <TableCell>{''}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <>
              {new Array(3).fill(1).map((a, idx) => (
                <TableRow key={idx} className={classes.tableLoader}>
                  <TableCell colSpan={5}>
                    <Skeleton height="50px" />
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
          {!isLoading && (
            <>
              {documents.map((document) => (
                <TableRow key={document.status}>
                  <TableCell>{BlueSnapDocumentTypeMapping[document.docType]}</TableCell>
                  <TableCell>{document.docGenericDescription}</TableCell>
                  <TableCell>{document.docUnderwriterNotes || '-'}</TableCell>
                  <TableCell>
                    <Chip
                      className={
                        document.status === BlueSnapDocumentStatusMapping.received
                          ? classes.chipSuccess
                          : classes.chipError
                      }
                      size="small"
                      label={document.status}
                    />
                  </TableCell>
                  <TableCell>
                    {document.status === BlueSnapDocumentStatusMapping.requested && (
                      <Button
                        className={classes.uploadDocumentBtn}
                        variant="contained"
                        onClick={() => handleUploadDialogOpen(document)}
                        size="small"
                      >
                        {translate('Upload Document')}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {/* {documents && documents.length && (
                <TableRow>
                  <TableCell>{translate('Other Document')}</TableCell>
                  <TableCell>{translate('Upload any other document requested here')}</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <Button
                      className={classes.uploadDocumentBtn}
                      variant="contained"
                      onClick={() => handleUploadDialogOpen(document)}
                      size="small"
                    >
                      {translate('Upload Document')}
                    </Button>
                  </TableCell>
                </TableRow>
              )} */}
              {!documents.length && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <span>{translate('No results found')}</span>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <UploadDocumentDialog
        open={showUploadDialog}
        document={selectedDocument}
        onClose={() => setShowUploadDialog(false)}
      />
    </div>
  )
}

export default DocumentTable
