import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { orgSelectors } from 'ducks/orgs'
import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import { TinyCircularProgress } from 'elements/progress/TinyCircularProgress'
import { Checkbox, EditOutlineIcon, IconButton } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { Theme } from 'types/themes'
import { WorkflowActionType } from 'types/workflows'
import { formatDate } from 'util/date'
import ConfirmModal from '../common/ConfirmModal'
import ProjectEventWrapper from '../details/elements/history/ProjectEventWrapper'
import { COLOR_PALETTE, getProjectSectionStyles, IconButtonTooltip } from '../styles'
import { deleteProjectEvent, updateProjectEvents } from './util'

const restClientInstance = restClient(window.API_ROOT + '/api')
const useStyles = makeStyles((theme: Theme) => ({
  actionLabel: {
    fontSize: 16,
  },
  marginLeft: {
    marginLeft: 'auto',
  },
  timestamp: {
    fontSize: 12,
    color: COLOR_PALETTE.iconColor,
  },
  actionWrapper: { marginBottom: 10 },
  fullWidth: { width: '100%', marginLeft: 6, gap: 6 },
}))
interface PropTypes {
  orgUrl: string
  action: WorkflowActionType
  disabled: boolean
}

const ActionCard: React.FC<PropTypes> = ({ action, disabled, orgUrl }) => {
  const form = useForm()
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const sectionStyles = getProjectSectionStyles()
  const currOrgUrl = useSelector(orgSelectors.getOrgUrl)
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  const [loading, setLoading] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const actionEvent = useMemo(() => {
    const events = project?.actions
      ?.find((x) => x.id === action.id)
      ?.events?.filter((z) => z.is_complete && !z.is_archived)
    if (events && !!events.length) return events[0]
  }, [action.id, project?.actions])
  const checked = useMemo(() => !!actionEvent, [action, actionEvent])
  const formattedDate = useMemo(() => (actionEvent ? formatDate(actionEvent.completion_date, true) : undefined), [
    actionEvent,
  ])

  const toggleChange = () => {
    const method = checked ? 'DELETE' : 'CREATE'
    const data = checked ? {} : { action: action.url, project: project.url, is_complete: true, event_type_id: 32 }
    const payload = checked ? { id: actionEvent?.id, data: data } : { data: data }
    restClientInstance(method, 'events', payload)
      .then((response) => {
        const latestProject = form.getState().values
        if (response?.data?.is_complete) {
          updateProjectEvents(response, latestProject, form)
          notify('Action marked as complete', 'success')
        } else {
          deleteProjectEvent(latestProject, form, payload.id, action)
          notify('Action marked as incomplete', 'success')
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        notify('Unable to update action: ' + err, 'warning')
      })
  }

  const handleCheck = (e) => {
    setLoading(true)
    if (checked && actionEvent) {
      if (actionEvent.notes) {
        setConfirmModal(true)
      } else {
        toggleChange()
      }
    } else {
      toggleChange()
    }
  }
  const editDisabled = useMemo(() => actionEvent && actionEvent?.org !== currOrgUrl, [currOrgUrl, actionEvent])
  const eventOrgName = useMemo(() => connectedOrgs.find((x) => x.org_url === actionEvent?.org)?.org_name, [
    connectedOrgs,
    actionEvent,
  ])
  return (
    <ProjectEventWrapper>
      {({ toggleModal }) => {
        return (
          <>
            <div className={`${sectionStyles.row} ${classes.actionWrapper}`}>
              <Tooltip
                title={
                  editDisabled
                    ? eventOrgName
                      ? translate(
                          'This action was marked as completed by %{org_name}. Only %{org_name} can edit or mark it as incomplete.',
                          { org_name: eventOrgName }
                        )
                      : translate(
                          'This action was marked as completed by an external org. You can not edit or mark it as incomplete.'
                        )
                    : ''
                }
              >
                <div>
                  {loading ? (
                    <TinyCircularProgress />
                  ) : (
                    <Checkbox
                      checked={checked}
                      onChange={handleCheck}
                      color="primary"
                      size="small"
                      disabled={disabled || editDisabled}
                      checkedIcon={<CheckBoxIcon style={{ color: '#1890FF' }} />}
                    />
                  )}
                </div>
              </Tooltip>
              <div className={`${sectionStyles.column} ${classes.fullWidth}`}>
                <span className={classes.actionLabel}>{action.title}</span>
                {checked && formattedDate && (
                  <span className={classes.timestamp}>{`${translate('Completed')} ${formattedDate}`}</span>
                )}
              </div>
              <div className={classes.marginLeft}>
                {actionEvent && checked && (
                  <IconButtonTooltip title={translate('Edit Event')}>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        toggleModal(actionEvent)
                      }}
                    >
                      <EditOutlineIcon variant={2} />
                    </IconButton>
                  </IconButtonTooltip>
                )}
                {!!project?.shared_with?.length && (
                  <SharedEntityIcon
                    owner_url={orgUrl}
                    shared_urls={action.share_with_orgs}
                    projectSharedOrgs={project?.shared_with}
                    version={3}
                    shared_with={project?.shared_with}
                  />
                )}
              </div>
            </div>
            <ConfirmModal
              open={confirmModal}
              setOpen={setConfirmModal}
              handleSubmit={toggleChange}
              title={translate('Uncheck Workflow Action')}
              mainText={translate('Are you sure you want to uncheck this action?')}
              subText={translate('Unchecking this action will delete the note associated with it.')}
              submitBtnLabel={translate('Confirm')}
              submitBtnProps={{ variant: 'contained', color: 'primary' }}
            />
          </>
        )
      }}
    </ProjectEventWrapper>
  )
}

export default ActionCard
