import { Tooltip } from '@material-ui/core'
import { CalendarTodayOutlined } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/ClearOutlined'
import { DateTimePicker, IconButton, OsDateTimePickerVersion, styled, useComponentVersion } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import PropTypes from 'prop-types'
import { FieldTitle, useInput, useLocale, useTranslate } from 'react-admin'
import { getDateFormat, parseDateObject, parseInputValue } from 'util/date'

const FullWidthDateTimePicker = styled(DateTimePicker)({
  width: '100%',
})

const DateTimeInput = ({
  label,
  options,
  source,
  resource,
  helperText,
  margin = 'dense',
  onBlur,
  onChange,
  onFocus,
  optionsTime,
  labelTime,
  validate,
  hideClearButton,
  inputVariant,
  variant = 'filled',
  disablePast = false,
  disabled,
  style = {},
  dataFormatter = getDateFormat,
  fullWidth,
  ...rest
}) => {
  const { id, input, isRequired } = useInput({
    onBlur,
    onChange,
    onFocus,
    resource,
    source,
    validate,
    ...rest,
  })
  const locale = useLocale()
  let format = dataFormatter({ locale, long: false })
  // format = '>>>'

  const translate = useTranslate()
  const handleChange = (date) => {
    input.onChange(parseDateObject(date)?.toISOString() || null)
    input.onBlur()
  }

  const clearDate = () => {
    input.onChange(null)
    input.onBlur()
  }
  const isVersion2 = useComponentVersion('date_time_picker') === OsDateTimePickerVersion.V2
  const value = parseInputValue(input?.value, isVersion2)
  return (
    <div
      id={id}
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: fullWidth ? '100%' : 256,
        ...style,
      }}
    >
      <FullWidthDateTimePicker
        variant="inline"
        ampm={true}
        label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
        value={value}
        onChange={handleChange}
        autoOk
        format={format}
        margin={margin}
        onError={(validationError) => {
          if (validationError) {
            console.log('Date time validation error', validationError)
          }
        }}
        disablePast={disablePast}
        helperText={`${translate('Timezone')}: ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}
        style={{ width: '100%', marginRight: 0 }}
        disabled={disabled}
        InputProps={{
          endAdornment:
            !disabled && !hideClearButton && input.value ? (
              <Tooltip title={translate('Clear Date')} enterDelay={300}>
                <IconButton
                  disabled={disabled}
                  onClick={clearDate}
                  style={{
                    padding: 5,
                    right: 0,
                    width: 30,
                    height: 30,
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <CalendarTodayOutlined style={{ height: 16, fill: COLOR_PALETTE.darkGrey }} />
            ),
        }}
        {...options}
      />
    </div>
  )
}

DateTimeInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
}

DateTimeInput.defaultProps = {
  options: {},
}

export default DateTimeInput
