//TODO: move this file to replace existing projectsections

import { makeStyles, Tooltip, withStyles } from '@material-ui/core'
import DateInput from 'elements/input/DateInput'
import { styled } from 'opensolar-ui'
import { Theme } from 'types/themes'

export const COLOR_PALETTE = {
  blue: '#F5F8FD',
  blue2: '#074BD8',
  blue3: '#4272DD',
  lightBlue: '#EBF0FB',
  darkBlue: '#113B98',
  darkBlue2: '#1751D0',
  red: '#FFE3E3',
  red2: '#E12121',
  darkRed: '#961616',
  lightGrey: '#ECECED',
  lightGrey2: '#FBFAFC',
  lightGrey3: '#FAFBFC',
  lightGrey4: '#B4B4B4',
  lightGrey5: '#f1f1f1',
  midGrey: '#EFF0F2',
  grey2: '#F8F8F8',
  darkGrey: '#4F4D55',
  iconColor: '#7F7D83',
  yellow: '#FFF6E7',
  darkYellow: '#C47E0A',
  teal: '#DFF1F7',
  darkTeal: '#005D7A',
  green: '#E1FAEA',
  darkGreen: '#016626',
  black: '#0A090B',
  green1: '#019939',
  green2: '#00D855',
  orange: '#EC980C',
  neutralGrey700: '#C9C9CC',
  neutralGrey500: '#E6E6E6',
}

export const getProjectSectionStyles = makeStyles(
  (theme: Theme) => ({
    iconPadding: {
      paddingLeft: 20,
      paddingRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    sectionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      padding: 40,
      width: '100%',
      maxWidth: 1440,
      boxSizing: 'border-box',
      gap: 30,

      '& > *': {
        maxWidth: '100%',
      },
    },
    sectionCardTop: {
      backgroundColor: theme.white,
      padding: 20,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
    },
    sectionCardMiddle: {
      backgroundColor: theme.white,
      padding: 20,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
    },
    sectionCardBottom: {
      backgroundColor: theme.white,
      padding: 20,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    chip: {
      marginRight: 5,
      height: '16px !important',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    moduleFullWidth: {
      width: '100%',
    },
    moduleHalfWidth: {
      width: '40%',
      flexGrow: 1,
      minWidth: 300,
    },
    accordionReskin: {
      width: '100%',
      borderRadius: 8,
      marginBottom: '-20px !important', // Makes the accordions at the bottom more tightly packed than the elements at the top.
      '& .MuiCollapse-container': {
        borderTop: '1px solid ' + COLOR_PALETTE.lightGrey,
      },
      '& .MuiAccordionSummary-content': {
        '& span': {
          fontWeight: 500,
        },
      },
    },
    menu: {
      // '& .MuiPopover-paper': {
      //   marginTop: 40,
      //   boxShadow: 'none',
      //   border: '1px solid',
      //   borderColor: theme.greyLight2,
      // },
      // '& svg': {
      //   paddingRight: 5,
      // },
      // '& .MuiList-padding': {
      //   padding: '2px 10px 2px 2px',
      // },
      // '& .MuiListItem-gutters': {
      //   padding: 5,
      //   fontSize: '14px',
      // },
    },
  }),
  { name: 'ProjectSectionStyles' }
)

export const IconButtonTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: COLOR_PALETTE.black,
    color: '#FFF',
    borderRadius: 8,
    padding: '8px 10px',
    fontWeight: 400,
  },
}))(Tooltip)

export const InputRow = styled('div')({
  display: 'flex',
  gap: 10,
})

export const InputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})

export const ContentBg = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const StyledDateInput = styled(DateInput)({
  width: '100%',
  margin: '8px 4px',
})
