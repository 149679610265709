import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Button as MaterialButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { DateInput } from 'elements/input/DateInput'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useGetMany, useNotify } from 'ra-core'
import { memo, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete'
import { RootState } from 'types/state'
import { formatDate } from 'util/date'
import { currencySymbolForCountry } from 'util/misc'
import { getAddressValue } from '../helper'
import { ContactAddressType } from '../solarOutlet/solarJuice/type'
import { useCheckoutStyles } from '../styles'
import { branchData } from './branchData'
import { CheckoutPresenterContext, DeliveryDetailContext } from './CityPlumbingCheckoutProvider'
import { getDeliveryFeeText } from './helper'
import { DeliveryBranchType, DeliveryDetailType, DeliveryMethodType } from './types'

type AddressType = {
  contactName: string
  contactNumber: string
  contactAddress: ContactAddressType
}

type SavedAddressType = AddressType & { value: string }

type Props = {
  showChangeShippingAddress: boolean
  setShowChangeShippingAddress: (value: boolean) => void
}

const timeOptions = [
  { id: 'before_10_30_am', name: 'Before 10:30 AM' },
  { id: 'am', name: 'AM (After 10:30 AM)' },
  { id: 'pm', name: 'PM' },
]

const computeShippingFee = (date, time) => {
  const diff = moment(date).startOf('day').diff(moment().startOf('day'), 'days')
  if (diff === 1) {
    return '75.00'
  } else if (diff >= 2 && diff < 5) {
    if (time === 'before_10_30_am') {
      return '75.00'
    } else {
      return '40.0'
    }
  } else if (diff >= 5) {
    if (time === 'before_10_30_am') {
      return '25.00'
    } else {
      return 'FREE'
    }
  }
  return 'TBC'
}

const DeliveryMethod = ({ showChangeShippingAddress, setShowChangeShippingAddress }: Props) => {
  const notify = useNotify()
  const checkoutClasses = useCheckoutStyles()
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const org = useSelector(orgSelectors.getOrg)
  const role = useSelector(authSelectors.getCurrentRole)
  const user = useSelector(authSelectors.getCurrentUser)
  const deliveryDetail: DeliveryDetailType = useContext(DeliveryDetailContext)
  const checkoutPresenter = useContext(CheckoutPresenterContext)
  const currencySymbol = useSelector((state: any) => currencySymbolForCountry(orgSelectors.getOrgIso2(state)))
  const now = new Date()
  const deliveryMinDate = new Date()
  const clickAndCollectMinDate = new Date()
  deliveryMinDate.setDate(now.getDate() + 1)
  clickAndCollectMinDate.setDate(now.getDate() + 3)

  let contactName = `${role?.first_name} ${role?.family_name}`
  if (contactName.trim() === '') {
    contactName = user?.email || 'Unknown name'
  }
  const contactAddress = {
    address: org?.address || '',
    locality: org?.locality || '',
    state: org?.state || '',
    zip: org?.zip || '',
    countryName: org?.country?.name || '',
    countryIso2: org?.country?.iso2 || '',
  }
  const [savedAddresses, setSavedAddresses] = useState<any>([
    {
      companyName: org?.name || 'Unknown',
      contactName: contactName,
      contactNumber: org?.sales_phone_number || '',
      contactAddress: {
        address: org?.address || '',
        locality: org?.locality || '',
        state: org?.state || '',
        zip: org?.zip || '',
        countryName: org?.country?.name || '',
        countryIso2: org?.country?.iso2 || '',
      },
      value: getAddressValue(contactName, contactAddress),
    },
  ])
  const projectIds = orderSelectors.getProjectListByLineItems(lineItems)
  const { data: projectsData } = useGetMany('projects', projectIds)
  const apiKeyGoogle = useSelector((state: RootState) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )

  useEffect(() => {
    if (!isEmpty(projectsData)) {
      const addresses = savedAddresses
      const projectAddresses = projectsData.map((item) => {
        const contactName = !isEmpty(item?.contacts_data) ? item.contacts_data[0].display : item?.org_name
        const contactNumber = !isEmpty(item?.contacts_data)
          ? item.contacts_data[0].phone
          : deliveryDetail?.contactNumber
        const contactAddress: ContactAddressType = {
          address: item?.address || '',
          locality: item?.locality || '',
          state: item?.state || '',
          zip: item?.zip || '',
          countryName: deliveryDetail?.contactAddress.countryName || '',
          countryIso2: deliveryDetail?.contactAddress.countryIso2 || '',
        }
        return {
          contactName,
          contactNumber,
          contactAddress,
          value: getAddressValue(contactName, contactAddress),
        }
      })
      setSavedAddresses([...addresses, ...projectAddresses])
    }
  }, [projectsData])

  const handleClickChangeShippingAddress = () => {
    setShowChangeShippingAddress(!showChangeShippingAddress)
  }

  const handleSelectSavedAddresses = (event: React.ChangeEvent<HTMLInputElement>) => {
    const address = savedAddresses.find((item) => item.value === event.target.value)
    if (address) {
      checkoutPresenter?.updateDeliveryDetail({
        ...deliveryDetail,
        contactName: address.contactName,
        contactNumber: address.contactNumber,
        contactAddress: address.contactAddress,
      })
    }
    setShowChangeShippingAddress(false)
  }

  const handleChangeDeliveryMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkoutPresenter?.updateDeliveryDetail({
      ...deliveryDetail,
      deliveryMethod: event.target.value as DeliveryMethodType,
      deliveryDate: '',
      deliveryTime: '',
      deliveryBranch: {
        name: '',
        manager: '',
        email: '',
        contactNumber: '',
        address: '',
        weekdayHours: '',
        saturdayHours: '',
        sundayHours: '',
      },
      fee: event.target.value === 'delivery' ? 'TBC' : 'FREE',
    })
  }

  const handlePlaceSelected = (placeAsFields) => {
    if (placeAsFields.country_iso2 !== 'GB') {
      notify('Please select a UK address', 'error')
    } else {
      checkoutPresenter?.updateDeliveryDetail({
        ...deliveryDetail,
        companyName: org?.name || 'Unknown',
        contactName: contactName,
        contactNumber: org?.sales_phone_number || '',
        contactAddress: {
          address: placeAsFields?.address || '',
          locality: placeAsFields?.locality || '',
          state: placeAsFields?.state || '',
          zip: placeAsFields?.zip || '',
          countryName: org?.country?.name || '',
          countryIso2: org?.country?.iso2 || '',
        },
      })
      setShowChangeShippingAddress(false)
    }
  }

  const handleUpdateDeliveryDateAndTime = ({ date, time }: { date?: any; time?: any }) => {
    const shippingFee = computeShippingFee(date, time)
    checkoutPresenter?.updateDeliveryDetail({
      ...deliveryDetail,
      deliveryDate: date,
      deliveryTime: time,
      fee: shippingFee,
    })
  }

  const handleUpdateClickAndCollectDate = ({ date }) => {
    checkoutPresenter?.updateDeliveryDetail({
      ...deliveryDetail,
      deliveryDate: date,
      fee: 'FREE',
    })
  }

  const handlUpdateDeliveryInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkoutPresenter?.updateDeliveryDetail({
      ...deliveryDetail,
      deliveryInstructions: event.target.value,
    })
  }

  const handleUpdateBranchData = (event) => {
    const data: DeliveryBranchType = branchData.find((item) => item.name === event.target.value) as DeliveryBranchType
    checkoutPresenter?.updateDeliveryDetail({
      ...deliveryDetail,
      deliveryBranch: data,
    })
  }

  return (
    <Grid container direction="row" justify="space-between" style={{ padding: '20px 0px' }}>
      <Grid item xs={3}>
        <Typography variant="h6" className={`${checkoutClasses.font18} ${checkoutClasses.fontWeightMedium}`}>
          <span style={{ marginRight: '20px' }}>2</span>
          <span>Shipping address</span>
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="row">
          {!showChangeShippingAddress && (
            <Grid item xs={12}>
              <Typography
                className={checkoutClasses.font16}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  letterSpacing: '0.32px',
                }}
              >
                <span>{deliveryDetail.contactName}</span>
                <span>{deliveryDetail.contactAddress.address}</span>
                <span>{`${deliveryDetail.contactAddress.locality} ${deliveryDetail.contactAddress.state} ${deliveryDetail.contactAddress.zip} ${deliveryDetail.contactAddress.countryIso2}`}</span>
              </Typography>
            </Grid>
          )}
          {showChangeShippingAddress && (
            <Grid item xs={9}>
              <Typography variant="h6" className={`${checkoutClasses.font16} ${checkoutClasses.fontWeightMedium}`}>
                <span>Select custom address</span>
              </Typography>
              <AddressAutocomplete
                onPlaceSelected={handlePlaceSelected}
                api_key_google={apiKeyGoogle}
                types={['address']}
              />
              {savedAddresses && savedAddresses.length > 1 && (
                <>
                  <Typography
                    variant="h6"
                    className={`${checkoutClasses.font16} ${checkoutClasses.fontWeightMedium}`}
                    style={{ marginTop: '16px' }}
                  >
                    <span>Saved addresses</span>
                  </Typography>
                  <RadioGroup
                    name="savedAddresses"
                    style={{ marginTop: '4px' }}
                    value={getAddressValue(deliveryDetail.contactName, deliveryDetail.contactAddress)}
                    onChange={handleSelectSavedAddresses}
                  >
                    {savedAddresses.map((item: SavedAddressType, index: number) => (
                      <FormControlLabel
                        key={`delivery-details-saved-address-${index}`}
                        value={item.value}
                        control={<Radio className={checkoutClasses.radio} />}
                        label={`${item.contactName} ${item.contactAddress.address} ${item.contactAddress.locality} ${item.contactAddress.state} ${item.contactAddress.zip} ${item.contactAddress.countryIso2}`}
                      />
                    ))}
                  </RadioGroup>
                </>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography
              variant="h6"
              className={`${checkoutClasses.font16} ${checkoutClasses.fontWeightMedium}`}
              style={{ marginTop: '16px' }}
            >
              <span>Select Delivery Method</span>
            </Typography>
            <RadioGroup
              name="deliveryMethod"
              style={{ marginTop: '4px', gap: '20px' }}
              value={deliveryDetail?.deliveryMethod}
              onChange={handleChangeDeliveryMethod}
            >
              <FormControlLabel
                key={'delivery-method-delivery'}
                value={'delivery'}
                control={<Radio className={checkoutClasses.radio} />}
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
                label={
                  <Grid container direction="row" style={{ width: '530px' }}>
                    <Grid item xs={10}>
                      <Typography className={`${checkoutClasses.font14}`}>Delivery</Typography>
                      <Typography
                        className={checkoutClasses.font14}
                        style={{
                          color: '#7F7D83',
                        }}
                      >
                        {`The delivery cost is calculated after the preferred date and time have been selected.`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        className={`${checkoutClasses.font14} ${checkoutClasses.fontWeightMedium}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {deliveryDetail.deliveryMethod === 'delivery'
                          ? getDeliveryFeeText(deliveryDetail.fee, currencySymbol)
                          : 'TBC'}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
              <Grid
                container
                direction="row"
                style={{ width: '100%', alignItems: 'center', gap: '10px', marginLeft: '40px' }}
              >
                <Grid item xs={4}>
                  <DateInput
                    fullWidth
                    size="small"
                    value={
                      deliveryDetail.deliveryDate && deliveryDetail.deliveryMethod === 'delivery'
                        ? formatDate(deliveryDetail.deliveryDate)
                        : null
                    }
                    onChange={(date) => handleUpdateDeliveryDateAndTime({ date, time: deliveryDetail.deliveryTime })}
                    inputVariant="outlined"
                    label="Ship by"
                    placeholder="Select date"
                    style={{ marginTop: '16px' }}
                    minDate={deliveryMinDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Time</InputLabel>
                    <Select
                      value={deliveryDetail.deliveryTime}
                      onChange={(e) =>
                        handleUpdateDeliveryDateAndTime({ date: deliveryDetail.deliveryDate, time: e.target.value })
                      }
                    >
                      {timeOptions.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControlLabel
                key={'delivery-method-click-and-collect'}
                value={'click_and_collect'}
                control={<Radio className={checkoutClasses.radio} />}
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
                label={
                  <Grid container direction="row" style={{ width: '530px' }}>
                    <Grid item xs={10}>
                      <Typography className={`${checkoutClasses.font14}`}>Click & Collect</Typography>
                      <Typography
                        className={checkoutClasses.font14}
                        style={{
                          color: '#7F7D83',
                        }}
                      >
                        {`Select your preferred branch where you would like to pick up your order - City Plumbing will notify you via email and text if it’s ready.`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        className={`${checkoutClasses.font14} ${checkoutClasses.fontWeightMedium}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        FREE
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
              <Grid container direction="column" style={{ width: '100%', gap: '10px', paddingLeft: '40px' }}>
                <Grid item xs={4}>
                  <DateInput
                    fullWidth
                    size="small"
                    value={
                      deliveryDetail.deliveryDate && deliveryDetail.deliveryMethod === 'click_and_collect'
                        ? formatDate(deliveryDetail.deliveryDate)
                        : null
                    }
                    onChange={(date) => handleUpdateClickAndCollectDate({ date })}
                    inputVariant="outlined"
                    label="Ship by"
                    placeholder="Select date"
                    style={{ marginTop: '16px' }}
                    minDate={clickAndCollectMinDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Branch</InputLabel>
                    <Select value={deliveryDetail.deliveryBranch?.name} onChange={(e) => handleUpdateBranchData(e)}>
                      {branchData.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {!isEmpty(deliveryDetail.deliveryBranch?.name) && (
                  <Grid item xs={10}>
                    <Grid
                      container
                      direction="row"
                      style={{ width: '100%', marginTop: '20px', alignItems: 'space-between' }}
                    >
                      <Grid item xs={4}>
                        <Typography className={`${checkoutClasses.font14}`}>Branch Manager</Typography>
                        <Typography
                          className={checkoutClasses.font14}
                          style={{
                            color: '#7F7D83',
                          }}
                        >
                          {deliveryDetail.deliveryBranch?.manager}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography className={`${checkoutClasses.font14}`}>Contact Information</Typography>
                        <Typography
                          className={checkoutClasses.font14}
                          style={{
                            color: '#7F7D83',
                          }}
                        >
                          {`${deliveryDetail.deliveryBranch?.email} / ${deliveryDetail.deliveryBranch?.contactNumber}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ width: '100%', marginTop: '20px' }}>
                      <Grid item xs={4}>
                        <Typography className={`${checkoutClasses.font14}`}>Opening Hours</Typography>
                        <Typography
                          className={checkoutClasses.font14}
                          style={{
                            color: '#7F7D83',
                          }}
                        >
                          {`Weekday: ${deliveryDetail.deliveryBranch?.weekdayHours}`}
                        </Typography>
                        <Typography
                          className={checkoutClasses.font14}
                          style={{
                            color: '#7F7D83',
                          }}
                        >
                          {`Saturday: ${deliveryDetail.deliveryBranch?.saturdayHours}`}
                        </Typography>
                        <Typography
                          className={checkoutClasses.font14}
                          style={{
                            color: '#7F7D83',
                          }}
                        >
                          {`Sunday: ${deliveryDetail.deliveryBranch?.sundayHours}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography className={`${checkoutClasses.font14}`}>Address</Typography>
                        <Typography
                          className={checkoutClasses.font14}
                          style={{
                            color: '#7F7D83',
                          }}
                        >
                          {deliveryDetail.deliveryBranch?.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item xs={9} style={{ marginTop: '16px' }}>
                <Typography variant="h6" className={`${checkoutClasses.font16} ${checkoutClasses.fontWeightMedium}`}>
                  <span>Delivery Instructions</span>
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter delivery instructions here"
                  multiline
                  rows={4}
                  rowsMax={4}
                  style={{ width: '100%', marginTop: '10px' }}
                  value={deliveryDetail.deliveryInstructions}
                  onChange={handlUpdateDeliveryInstructions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <MaterialButton variant="text" onClick={handleClickChangeShippingAddress} style={{ height: '22px' }}>
          <Typography
            variant="h6"
            className={checkoutClasses.font14}
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: '#113B98',
            }}
          >
            <span>{showChangeShippingAddress ? 'Close' : 'Change'}</span>
            {showChangeShippingAddress && (
              <CloseOutlined style={{ marginLeft: '8px', fontSize: '24px', color: '#1C1B1F' }} />
            )}
          </Typography>
        </MaterialButton>
      </Grid>
    </Grid>
  )
}

export default memo(DeliveryMethod)
