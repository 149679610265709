import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { AddOutlined, DeleteOutline } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import LocalizedCurrencyInput from 'elements/input/LocalizedCurrencyInput'
import { Button, Chip, Switch } from 'opensolar-ui'
import SurchargingTooltip from 'pages/cashFlow/configuration/paymentMilestones/SurchargingTooltip'
import { getIsSurchargingAllowed, useGetPaymentMethodChoices } from 'pages/cashFlow/configuration/utils'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { Field, Form, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StandalonePaymentRequestSettings } from './CustomInvoiceButton'
import { DEFAULT_LINE_ITEM } from './constants'
import { LineItem } from './types'

type PropTypes = {
  makeInvoiceDraft: (invoiceLineItems: LineItem[], paymentRequestSettings: StandalonePaymentRequestSettings) => void
  lineItems: LineItem[]
  paymentRequestSettings: StandalonePaymentRequestSettings | undefined
  isLoading: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  actionsRow: {
    padding: '10px',
    borderTop: `1px solid ${theme.greyLight2}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItemTitleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '15px',
  },
  lineItemTitle: {
    color: theme.greyMid1,
    fontWeight: 'bold',
  },
  btnWrapper: {
    padding: '0px 10px',
  },
  deleteIcon: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  pmtMethodWrapper: {
    marginRight: '4px',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  selectLabel: {
    marginBottom: 5,
  },
}))

const ConfigureCustomInvoiceFormContents: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const translate = useTranslate()
  const form = useForm()
  const lineItems: LineItem[] = useFormState().values?.lineItems
  const paymentMethodChoices = useGetPaymentMethodChoices()
  const orgCountry = useSelector(orgSelectors.getOrg)?.country?.iso2

  const surchargingIsAllowed = useMemo(() => {
    return getIsSurchargingAllowed(orgCountry)
  }, [orgCountry])

  const getPaymentMethodTitle = (pmtMethodId) => {
    const pmtMethod = paymentMethodChoices?.find((pmtMethod) => pmtMethod.id === pmtMethodId)
    return pmtMethod?.name
  }

  const defaultPaymentMethodsValue = useMemo(() => {
    // default to everything except debit card
    return paymentMethodChoices?.filter((choice) => !choice.id?.includes('debit_card'))?.map((choice) => choice.id)
  }, [paymentMethodChoices])

  // initialize the form with the default payment methods for this country
  useEffect(() => {
    if (props.paymentRequestSettings?.accepted_payment_methods) {
      form.change('accepted_payment_methods', props.paymentRequestSettings?.accepted_payment_methods)
    } else {
      form.change('accepted_payment_methods', defaultPaymentMethodsValue)
    }
    if (surchargingIsAllowed) {
      if (form.getState().values.surcharging_enabled === undefined) {
        form.change('surcharging_enabled', true)
      }
    }
  }, [defaultPaymentMethodsValue, props.paymentRequestSettings?.accepted_payment_methods, surchargingIsAllowed])

  const validateForm = () => {
    let isValid = true
    if (!form.getState().values.title) {
      notify('Must have a title for this invoice', 'warning')
      isValid = false
    }
    if (!form.getState().values.description) {
      notify('Must have a description for this invoice', 'warning')
      isValid = false
    }
    if (!form.getState().values?.accepted_payment_methods?.length) {
      notify('Must have at least one accepted payment method', 'warning')
      isValid = false
    }
    if (!lineItems?.length) {
      notify('Must have at least one line item', 'warning')
      isValid = false
    }
    lineItems?.forEach((lineItem, i) => {
      if (!lineItem.product_name) {
        notify(`Line item ${i + 1} must have a product name`, 'warning')
        isValid = false
      } else if (!lineItem.quantity || lineItem.quantity <= 0) {
        notify(`Line item ${i + 1} must have a quantity greater than 0`, 'warning')
        isValid = false
      } else if (!lineItem.price || lineItem.price <= 0) {
        notify(`Line item ${i + 1} must have a price greater than 0`, 'warning')
        isValid = false
      } else if (lineItem.tax_rate < 0) {
        notify('Line item ${i + 1} may not have a negative tax rate', 'warning')
        isValid = false
      }
    })
    return isValid
  }

  const onSubmit = (e: any) => {
    if (validateForm()) {
      props.makeInvoiceDraft(lineItems, {
        title: form.getState().values.title,
        description: form.getState().values.description,
        accepted_payment_methods: form.getState().values.accepted_payment_methods,
        surcharging_enabled: form.getState().values.surcharging_enabled,
      })
    }
  }

  const addLineItem = () => {
    form.change('lineItems', [...lineItems, DEFAULT_LINE_ITEM])
  }

  const removeLineItem = (index: number) => {
    form.change(
      'lineItems',
      lineItems.filter((_, i) => i !== index)
    )
  }

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>Create Custom Invoice</h1>
            <Typography variant="body1" style={{ textAlign: 'justify', lineHeight: '1.5' }}>
              {translate(
                'Enter data in the fields below to create a standalone invoice for this project. Note that values on this invoice will not impact the overall system price in OpenSolar, nor will they change the values on any other open invoice. This invoice will follow the payment and communications schedule set in your orgs Invoice Settings.'
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Field name={`title`}>
              {(fieldProps) => (
                <div>
                  <TextField
                    name={fieldProps.input.name}
                    value={fieldProps.input.value}
                    label="Title"
                    onChange={fieldProps.input.onChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </div>
              )}
            </Field>
          </Grid>
          <Grid item xs={8}>
            <Field name={`description`}>
              {(fieldProps) => (
                <div>
                  <TextField
                    name={fieldProps.input.name}
                    value={fieldProps.input.value}
                    label="Description"
                    onChange={fieldProps.input.onChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </div>
              )}
            </Field>
          </Grid>
          <Grid item xs={9}>
            <Field name="accepted_payment_methods">
              {(fieldProps) => (
                <>
                  <InputLabel id="pmt-methods-select" className={classes.selectLabel}>
                    Payment Methods
                  </InputLabel>
                  <Select
                    labelId="pmt-methods-select"
                    multiple
                    value={fieldProps.input.value || []}
                    onChange={fieldProps.input.onChange}
                    fullWidth={true}
                    size="small"
                    input={<OutlinedInput id="select-multiple-chip" />}
                    // @ts-ignore
                    renderValue={(selected: string[]) => (
                      <>
                        {selected.map((value) => (
                          <Chip
                            size="small"
                            key={value}
                            label={getPaymentMethodTitle(value)}
                            className={classes.pmtMethodWrapper}
                          />
                        ))}
                      </>
                    )}
                  >
                    {paymentMethodChoices?.map((choice) => (
                      <MenuItem key={choice.id} value={choice.id}>
                        {choice.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Field>
          </Grid>
          <Grid item xs={3}>
            {surchargingIsAllowed && (
              <Field name={`surcharging_enabled`}>
                {(fieldProps) => (
                  <div>
                    <InputLabel id="demo-multiple-chip-label">Enable Surcharging?</InputLabel>
                    <div className={classes.row}>
                      <Switch
                        name={fieldProps.input.name}
                        value={fieldProps.input.value}
                        onChange={(e) => form.change(fieldProps.input.name, e.target.checked)}
                        defaultChecked={true}
                      />
                      <SurchargingTooltip />
                    </div>
                  </div>
                )}
              </Field>
            )}
          </Grid>
          {lineItems?.map((lineItem, index) => (
            <>
              <Grid item xs={12}>
                <div className={classes.lineItemTitleRow}>
                  <div className={classes.lineItemTitle}>Line Item {index + 1}</div>
                  <div className={classes.deleteIcon}>
                    {index > 0 && <DeleteOutline onClick={() => removeLineItem(index)} />}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field name={`lineItems.${index}.product_name`}>
                  {(fieldProps) => (
                    <div>
                      <TextField
                        name={fieldProps.input.name}
                        value={lineItems[index].product_name}
                        label="Product"
                        onChange={fieldProps.input.onChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Enter the name of the line item"
                      />
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name={`lineItems.${index}.product_description`}>
                  {(fieldProps) => (
                    <div>
                      <TextField
                        name={fieldProps.input.name}
                        value={lineItems[index].product_description}
                        label="Product Description"
                        onChange={fieldProps.input.onChange}
                        rows={3}
                        multiline
                        variant="outlined"
                        fullWidth
                        placeholder="Enter text here that will appear on the line item"
                      />
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Field name={`lineItems.${index}.quantity`}>
                  {(fieldProps) => (
                    <TextField
                      name={fieldProps.input.name}
                      label="Quantity"
                      onChange={fieldProps.input.onChange}
                      type="number"
                      variant="outlined"
                      size="small"
                      value={lineItems[index].quantity}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Field name={`lineItems.${index}.price`}>
                  {(fieldProps) => (
                    <LocalizedCurrencyInput
                      formatAsCurrency
                      currencySymbol="$"
                      input={{ name: fieldProps.input.name, source: fieldProps.input.name, label: 'Price' }}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Field name={`lineItems.${index}.discount`}>
                  {(fieldProps) => (
                    <LocalizedCurrencyInput
                      formatAsCurrency
                      currencySymbol="-$"
                      input={{ name: fieldProps.input.name, source: fieldProps.input.name, label: 'Discount' }}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Field name={`lineItems.${index}.tax_rate`}>
                  {(fieldProps) => (
                    <TextField
                      name={fieldProps.input.name}
                      label="Tax Rate"
                      type="number"
                      onChange={fieldProps.input.onChange}
                      variant="outlined"
                      size="small"
                      value={lineItems[index].tax_rate}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
            </>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsRow}>
          <div className={classes.btnWrapper}>
            <Button onClick={addLineItem} disabled={lineItems?.length >= 10 || props.isLoading}>
              <AddOutlined /> Add Line Item
            </Button>
          </div>
          <div className={classes.btnWrapper}>
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={props.isLoading}>
              {props.isLoading ? <CircularProgress /> : <span>Save & Preview</span>}
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  )
}

const ConfigureCustomInvoice: React.FC<PropTypes> = (props) => {
  const initialPaymentRequestSettings = useMemo(() => {
    return props.paymentRequestSettings ? props.paymentRequestSettings : {}
  }, [props.paymentRequestSettings])

  return (
    <Form onSubmit={() => {}} initialValues={{ lineItems: props.lineItems, ...initialPaymentRequestSettings }}>
      {({ handleSubmit }) => <ConfigureCustomInvoiceFormContents {...props} />}
    </Form>
  )
}

export default ConfigureCustomInvoice
