import { Typography } from '@material-ui/core'
import { orderSelectors } from 'ducks/orderComponents'
import { useTranslate } from 'ra-core'
import { useSelector } from 'react-redux'

const CheckoutTitle = () => {
  const translate = useTranslate()
  const totalLineItems = useSelector(orderSelectors.getOrderLineItems)

  return (
    <Typography variant="h4" gutterBottom align="center">
      {translate('checkout_total_items', { smart_count: totalLineItems.length })}
    </Typography>
  )
}

export default CheckoutTitle
