import { makeStyles } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { useViewShow } from 'ducks/viewMode'
import { useEffect } from 'react'
import { Layout, Sidebar, Title } from 'react-admin'
import { connect } from 'react-redux'
import { getRoleFromState } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import BottomNavigationMobile from './BottomNavigationMobile'
import MainHeader from './MainHeader'
import useMenu from './menus/useMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#ffffffb3',
    height: '100vh',
  },
  content: ({ zone }) => ({
    //hide this in dashboard
    borderLeft: theme.border.default,
    background: zone === 'control' ? theme.greyLight1 : '#ffffff', // can we have same background across whole app?
  }),
}))

const CustomLayout = (props) => {
  const { theme, children, title, zone, viewAsCustomer } = props
  const classes = useStyles({ zone })
  const showHeader = useViewShow('header')
  const proUXHeader = viewAsCustomer || !showHeader ? null : MainHeader
  const isTopNav3Enabled = useFeatureFlag('top_nav_3', 'on')

  const Menu = useMenu()

  return (
    <Layout
      id={theme}
      isTopNav3Enabled={isTopNav3Enabled}
      title={title}
      className={{
        root: classes.root,
        content: classes.content,
      }}
      sidebar={Sidebar}
      menu={Menu}
      header={proUXHeader}
      bottomNavigation={BottomNavigationMobile}
      error={Error}
    >
      {children}
    </Layout>
  )
}

export default connect((state) => {
  const role = getRoleFromState(state)
  return {
    zone: state.zone,
    isAdmin: Boolean(role) && role.is_admin,
    isPro: Boolean(role),
    viewAsCustomer: state.viewAsCustomer,
  }
})(CustomLayout)

// Accroding to https://marmelab.com/react-admin/doc/4.2/Layout.html#error
// If you want to customize this page, or log the error to a third-party service
// create your own <Error> component, and pass it to a custom Layout
const Error = ({ error }) => {
  // log error to Sentry
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <div>
      <Title title="Error" />
      <h1>Something Went Wrong</h1>
      <div>A client error occurred and your request couldn't be completed.</div>
    </div>
  )
}
