import { Divider, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import HotTub from '@material-ui/icons/HotTub'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import type LineItemType from 'pages/ordering/OrderLineItem'
import PageLayout from 'pages/ordering/PageLayout'
import VolumeDiscounts from 'pages/ordering/detail/content/price/VolumeDiscounts'
import { getDiscountSavingPerItem, getPercentageSavingPerItem } from 'pages/ordering/discountUtils'
import PercentageDiscountTag from 'pages/ordering/elements/PercentageDiscountTag'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { HardwareSupplierEnum } from '../type'
import ProductDetailDescription from './content/ProductDetailDescription'
import ProductDetailDescriptionActions from './content/ProductDetailDescriptionActions'
import ProductDetailTitle from './content/ProductDetailTitle'
import ProductImage from './content/ProductImage'
import TechnicalDetails from './content/TechnicalDetails'
import ProductPrice from './content/price/ProductPrice'
import useProductSpecsData from './useProductSpecsData'

const useStyles = makeOpenSolarStyles((theme) => ({
  imageWrapper: {
    position: 'relative',
    margin: '20px 0',
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #E7E7E7',
  },
  tagWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  divider: {
    margin: '20px 0',
  },
  message: {
    textAlign: 'center',
    opacity: 0.5,
  },
  icon: {
    width: 200,
    height: 200,
  },
}))

const ProductDetailNotFound = () => {
  const classes = useStyles()
  return (
    <div className={classes.message}>
      <HotTub className={classes.icon} />
      <Typography variant="h3" gutterBottom>
        Not Found
      </Typography>
      <Typography>Product not found, or you followed a bad link.</Typography>
    </div>
  )
}

export const ProductDetailContent = ({
  trackSeenComponent,
  ProductPrice,
  techDetail,
  description,
  descriptionActions,
  image,
  tag,
}: {
  trackSeenComponent(): void
  ProductPrice?: React.ReactNode
  techDetail: React.ReactNode
  description?: React.ReactNode
  descriptionActions: React.ReactNode
  image: React.ReactNode
  tag?: React.ReactNode
}) => {
  const classes = useStyles()
  useEffect(() => {
    trackSeenComponent()
  }, [])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <div className={classes.imageWrapper}>
          {tag && <div className={classes.tagWrapper}>{tag}</div>}
          {image}
        </div>
      </Grid>
      <Grid item xs={12} sm={8}>
        {ProductPrice != null && <div className={classes.divider}>{ProductPrice}</div>}
        <Divider />
        <div className={classes.divider}>{techDetail}</div>
        <Divider />
        {description && (
          <>
            <div className={classes.divider}>{description}</div>
            <Divider />
          </>
        )}
        <div className={classes.divider}>{descriptionActions}</div>
      </Grid>
    </Grid>
  )
}

const ProductDetail = ({
  trackSource,
  loading,
  lineItem,
  CtaAction,
  goBack,
}: {
  trackSource: string
  loading: boolean
  lineItem: LineItemType | undefined
  CtaAction: React.ReactNode
  goBack?(): void
}) => {
  const productDetailData = useProductSpecsData(lineItem)
  const trackSeenComponent = useCallback(
    () =>
      logAmplitudeEvent('generic_element_viewed', {
        source: trackSource,
        context: 'product_detail',
        componentCode: lineItem?.code,
        componentType: lineItem?.componentType,
      }),
    [lineItem]
  )
  const selectedHardwareSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  // Being overly cautious here to limit to HDM as it's the only one currently with HTML descriptions
  const description =
    selectedHardwareSupplier === HardwareSupplierEnum.Hdm ||
    selectedHardwareSupplier === HardwareSupplierEnum.VtacUk ||
    selectedHardwareSupplier === HardwareSupplierEnum.VtacPoland
      ? (
        lineItem?.data?.description === '0'
        ? (
          <div>No description available.</div>
          )
        :(
          <div dangerouslySetInnerHTML={{ __html: lineItem?.data?.description || '' }}></div>
        )
      ) : (
        lineItem?.data?.description
      )
  const price = lineItem?.selectedDistributorOrderingData?.price
  const productDocuments = lineItem?.selectedDistributorOrderingData?.product_documents

  if (loading) {
    return <FullScreenLoading />
  }
  if (!productDetailData || !lineItem) {
    return <ProductDetailNotFound />
  }
  const { discount } = lineItem.getBestDiscountOffer()
  const discountSavingPerItem =
    discount &&
    lineItem.selectedDistributorOrderingData &&
    getDiscountSavingPerItem({
      discount,
      pricePerItem: lineItem.selectedDistributorOrderingData.price,
    })

  return (
    <div>
      <PageLayout
        title={
          <ProductDetailTitle
            goBack={goBack}
            manufacturer={productDetailData.manufacturer_name}
            code={productDetailData.code}
          />
        }
        mainContent={
          <ProductDetailContent
            trackSeenComponent={trackSeenComponent}
            ProductPrice={
              <>
                <ProductPrice
                  pricePerItem={price}
                  discountSavingPerItem={discountSavingPerItem}
                  outputPower={(productDetailData.kw_stc || 0) * 1000}
                  quantityPerItem={lineItem.selectedDistributorOrderingData?.quantity_per_item}
                />
                {lineItem.selectedDistributorOrderingData && (
                  <VolumeDiscounts
                    outputPower={(productDetailData.kw_stc || 0) * 1000}
                    quantityPerItem={lineItem.selectedDistributorOrderingData.quantity_per_item}
                    componentType={lineItem.componentType}
                    discounts={lineItem.selectedDistributorOrderingData.volume_discounts}
                    pricePerItem={lineItem.selectedDistributorOrderingData.price}
                  />
                )}
              </>
            }
            techDetail={
              <TechnicalDetails
                productDetailData={productDetailData}
                componentType={lineItem.componentType}
                quantityPerItem={lineItem.quantityPerItem}
              />
            }
            description={description != null && <ProductDetailDescription description={description} />}
            descriptionActions={
              productDocuments && <ProductDetailDescriptionActions productDocuments={productDocuments} />
            }
            tag={
              discount != null && price ? (
                <PercentageDiscountTag
                  showUpTo={false}
                  percentage={getPercentageSavingPerItem({ discount, pricePerItem: price })}
                />
              ) : undefined
            }
            image={<ProductImage imageUrl={lineItem.data?.image_url} />}
          />
        }
        sideAction={CtaAction}
      />
    </div>
  )
}

export default ProductDetail
